import React, { useState } from "react";
import { Row, Col, Input, Form, Modal, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { CheckExistsTaxCode, CreateEsidCtcCmp, UpdateEsidCtcCmp } from "../../config/esidExportApi";
import { errorNotification } from "../../config/AuthIntercept";
import { useDispatch, useSelector } from "react-redux";
import { addAnItemToEsidCtcCmps, updateAnItemToEsidCtcCmps } from "../../actions/esidExportAction";
import { CodCtcTypEnum } from "../../constant/enumerations";
const RegisterModal = ({form, setVisible, visible, updateData, title, isUpdate, itemData}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const { cmpList } = useSelector((state) => state.esidExport);

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };
  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        codCtcTyp: title,
        namCtc: values.name,
        desAddStt: values.address,
        desEmail: values.email,
        desPhone: values.tel,
        desFax: values.fax,
        codTaxCmp: values.vat,
      };
      // console.log(data)
      let response;
      if(itemData?.id){
        response = await UpdateEsidCtcCmp(itemData?.id, data);
        let newList = cmpList.map((cmp)=>{
          let temp = cmp
          if(temp.id === response.data.id){
            temp = response.data
          }
          return temp
        })
        dispatch(updateAnItemToEsidCtcCmps(newList));
      }else{
        response = await CreateEsidCtcCmp(data);
        dispatch(addAnItemToEsidCtcCmps(response.data));
      }
      updateData(title, response.data);
      setLoading(false);
      setVisible(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    // if (title === CodCtcTypEnum.Shipper) setTypeErr("Shp");
    // else setTypeErr("Cne");
    // eslint-disable-next-line
  }, [visible, itemData]);

  const [taxCodeMsg, setTaxCodeMsg] = useState('');
  const [chkTimer, setChkTimer] = useState(null);
  const checkExistsTaxCode = (evt) => {
    clearTimeout(chkTimer);
    const timerId = setTimeout(async () => {
      try {
        const taxCode = evt.target.value;
        if (!taxCode) {
          setTaxCodeMsg('');
          return;
        }
        const result = await CheckExistsTaxCode({ taxCode });
        if (result?.data?.hasCmp) {
          setTaxCodeMsg('');
        } else {
          setTaxCodeMsg('Mã số thuế chưa được đăng ký tại TCS. Vui lòng liên hệ tại quầy giao dịch để được hổ trợ.');
        }
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Thông báo',
          description: 'Có lỗi xảy ra. Vui lòng thử lại sau.'
        });
      }  
    }, 500);
    setChkTimer(timerId);
  }

  return (
    <>
      <Modal
        title={isUpdate ? t(`formInfo.Update${title}`): t(`formInfo.Regis${title}`)}
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        width={'50%'}
      >
        <div>
          <Form
            layout="vertical"
            className="form-date"
            form={form}
            onFinish={handleFinish}
            autoComplete="off"
          >
            <Row className="row-input">
              <Col span={4}>
                <label> {t("formInfo.name")} </label>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="name"
                  style={{ margin: "0px" }}
                  rules={[
                    {
                      required: true,
                      message: `${t(`formInfo.name_Err`)}`,
                    },
                  ]}
                >
                  <Input type="text" name="name" maxLength={150} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="row-input">
              <Col span={4}>
                <label> {t("formInfo.address")} </label>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="address"
                  style={{ margin: "0px" }}
                  rules={[
                    {
                      required: title !== CodCtcTypEnum.Notify,
                      message: `${t(`formInfo.address_Err`)}`,
                    },
                  ]}
                >
                  <Input type="text" name="address" maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="row-input">
              <Col span={4}>
                <label> Email </label>
              </Col>
              <Col span={20}>
                <Form.Item name="email"
                  style={{ margin: "0px" }}
                  rules={[
                    {
                      type: 'email',
                      message: `${t(`formInfo.email_Err`)}`,
                    }
                  ]} >
                  <Input type="text" name="email" maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 0]} className="row-input">
              <Col span={12}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={8}>
                    <label> {t("formInfo.tel")} </label>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="tel" style={{ margin: "0px" }}>
                      <Input type="text" name="tel" maxLength={50}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={8}>
                    <label> Fax </label>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="fax" style={{ margin: "0px" }}>
                      <Input type="text" name="fax" maxLength={50} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row-input">
              <Col span={4}>
                <label> {t("formInfo.vatCode")} </label>
              </Col>
              <Col span={20}>
                <Form.Item name="vat" style={{ margin: "0px" }} help={taxCodeMsg} validateStatus={taxCodeMsg ? "error" : ""}>
                  <Input type="text" name="vat" onChange={checkExistsTaxCode} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end", height: "35px" }}>
              <Form.Item>
                <Button
                  style={{ width: "fit-content" }}
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                >
                  {t("formInfo.saveInfo")}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default RegisterModal;
