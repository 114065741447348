
import React from "react";
import { Row, Col, Typography } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import QRCode from "qrcode.react";
import { codPayModEnum, tempEnum } from "../constant/enumerations";
import moment from "moment";
import { DmsSizeToPrint } from "../pages/admin/EsidExport/components/Acceptance/DimensionSize/DmsSizeToPrint";
import { ExpDeclConsolPrint } from "./tracking/ExpDeclConsol/ExpDeclConsolPrint";
import { urlLogoTcs } from "../image/logotcs_base64";
export class RegistryExportPrint extends React.PureComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }
    render() {
        const { shipper,
            agent,
            consignee,
            notify,
            codOri,
            codFds,
            codFltNum,
            datFltOri,
            qtyPcs,
            totalOfHawbs,
            natureOfGoods,
            codAwbPfx,
            codAwbNum,
            codFltCar,
            shcPer,
            shcDgr,
            shcVal,
            shcVun,
            shcVunNml,
            shcEcort,
            shcReinforce,
            shcBup,
            // eslint-disable-next-line
            shcConsol,
            shcDip,
            shcAvi,
            shcHum,
            shcColStr,
            shcColTemReq,
            shcOth,
            shcOth001,
            shcOth002,
            shcOthReq,
            codPayMod,
            shpRegNam,
            shpRegTel,
            shpRegIdx,
            shpRegDat,
            acpStfNam,
            acpDat,
            dgrAcpStfNam,
            dgrAcpDat,
            expCarDim,
            expCarWgt,
            desRmk,
            desRmk001,
            usrCre
            // tcsCodRefNum
        } = this.props?.esidExport;
        const { dgrSelected, aviSelected, expConsol, imgQRUrl } = this.props;
        const qrCodeStr = `${codAwbPfx}${codAwbNum}`;
        const CheckBoxBlank = <span style={{ border: '1px solid black', marginRight: '5px'}}><CheckOutlined style={{ color: 'white' }} /></span>;
        const CheckBoxChecked = <span style={{ border: '1px solid black', marginRight: '5px'}}><CheckOutlined /></span>;
        return (
            <div style={{ width: 755 }}>
            <div style={{ display: "flex", justifyContent: "center", fontFamily: "sans-serif", lineHeight: 1.5 }}>
                <div style={{width: "100%", height: "100%"}}>
                    <Row justify="center" style={{position: 'relative', width: '100%'}}>
                        <Col span={24}>
                            <Row style={{ marginBottom: "0px", fontWeight: "600", display: 'flex', justifyContent: 'center'}}>
                                <Col style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px'}}>
                                    <Typography.Text style={{ fontSize: '18px', fontWeight: 'bold'}}>HƯỚNG DẪN GỬI HÀNG</Typography.Text>
                                </Col>
                                <Col>
                                    {imgQRUrl ? (
                                        <img alt="" src={imgQRUrl} width={55} height={55} />
                                    ) : (
                                        <QRCode style={{ width: '55px', height: '55px' }} value={`${qrCodeStr}`} />
                                    )}
                                </Col>
                            </Row>
                            <Row style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography.Text italic style={{ fontWeight: "400", fontSize: "15px", textAlign: "center", textTransform: "uppercase" }}>Shipper's Instruction for Despatch</Typography.Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{position: 'absolute', width: '100%', display: 'flex',  justifyContent: 'space-between'}}>
                            <div style={{width: '30%', height: '100%', marginLeft: '50px', marginTop: "16px"}}>
                                <img
                                    style={{ objectFit: "contain", width: "43%", height: "43%" }}
                                    className="logo"
                                    alt="logo"
                                    src={urlLogoTcs}
                                />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography.Text style={{textAlign: 'end'}}>QF/ED/31</Typography.Text>
                                <Typography.Text style={{textAlign: 'end', fontSize: '12px'}}>Ngày hiệu lực: 26/07/2024</Typography.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Typography.Text className="item-name" style={{fontWeight: 600}}>
                            I. Phần dành cho khách hàng/<span style={{fontStyle: 'italic'}}>Complete by customer</span>
                        </Typography.Text>
                    </Row>
                    <Row justify="center" style={{ border: "1px solid" }}>
                        <Col span={13} style={{borderRight: '1px solid'}}>
                            <div className="info-register-export" style={{ width: "100%", padding: "2px 5px", height: '100%', display: 'inline-grid', justifyItems: 'stretch' }}>
                                <div style={{ marginTop: '5px' }}>
                                    <Row>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography.Text className="item-name">1. Người gửi/<span>Shipper:</span> {shipper?.namCtc} </Typography.Text>
                                            <Typography.Text className="item-name">1.a. Địa chỉ/<span>Address: </span>  {shipper?.desAddStt} </Typography.Text>
                                            <Typography.Text className="item-name">1.b. Email: {shipper?.desEmail} </Typography.Text>
                                        </div>
                                    </Row>
                                    
                                    <Row>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">1.c Số ĐT/<span>Tel: </span> {shipper?.desPhone}</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">1.d. Fax: {shipper?.desFax}</Typography.Text>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {/* <Typography.Text className="item-name">1.e. MST/<span>VAT code: </span>{shipper?.codTaxCmp}</Typography.Text> */}
                                            <Typography.Text className="item-name">2. Đơn vị cung cấp dịch vụ/<span>Agent: </span> <b>{agent?.namCtc}</b></Typography.Text>
                                            <Typography.Text className="item-name">2.a. Địa chỉ/<span>Address: </span>  <b>{agent?.desAddStt}</b> </Typography.Text>
                                            <Typography.Text className="item-name">2.b. Email: {agent?.desEmail} </Typography.Text>
                                        </div>
                                    </Row>
                                    
                                    <Row>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">2.c Số ĐT/<span>Tel: </span>{agent?.desPhone}</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">2.d. Fax: {agent?.desFax}</Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography.Text className="item-name">2.e. MST/<span>VAT code: </span> <b>{agent?.codTaxCmp}</b> </Typography.Text>
                                        </div>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography.Text className="item-name">3. Người nhận/<span>Consignee: </span> {consignee?.namCtc} </Typography.Text>
                                            <Typography.Text className="item-name">3.a. Địa chỉ/<span>Address: </span>  {consignee?.desAddStt} </Typography.Text>
                                            <Typography.Text className="item-name">3.b. Email: {consignee?.desEmail} </Typography.Text>
                                        </div>
                                    </Row>
                                    
                                    <Row>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">3.c Số ĐT/<span>Tel: </span>{consignee?.desPhone}</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">3.d. Fax: {consignee?.desFax}</Typography.Text>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography.Text className="item-name">3.e. Thông báo cho/<span>Notify: </span> {notify?.namCtc} </Typography.Text>
                                            <Typography.Text className="item-name">3.f. Địa chỉ/<span>Address: </span>  {notify?.desAddStt} </Typography.Text>
                                        </div>
                                    </Row>
                                    
                                    <Row>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">3.g Số ĐT/<span>Tel: </span>{notify?.desPhone}</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text className="item-name">3.h. Fax: {notify?.desFax}</Typography.Text>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={24}>
                                            <Typography.Text className="item-name">3.i. Email: {notify?.desEmail} </Typography.Text>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col span={11}>
                            <div className="info-register-export" style={{ width: "100%" }}>
                                <Row style={{ borderBottom: "1px solid", width: "100%", height: "50px", padding: "2px 5px" }}>
                                    <Col span={24}>
                                        <Typography.Text className="item-name">4.Số không vận đơn/<span>Air Waybill number.</span> </Typography.Text>
                                    </Col>
                                    <Col span={24}>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Typography.Text style={{fontWeight: 600, fontSize: '19px'}}> {codAwbPfx}-{codAwbNum}</Typography.Text>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ borderBottom: "1px solid", width: "100%", height: "50px" }}>
                                    <Col span={12} style={{ borderRight: "1px solid", height: "50px", padding: "2px 5px" }}>
                                        <Typography.Text className="item-name">4.a. Điểm xuất phát/<span>Origin</span> </Typography.Text>
                                        <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                            <Typography.Text style={{ fontSize: '19px', fontWeight: 600 }}>{codOri}</Typography.Text>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{ padding: "2px 5px" }}>
                                        <Typography.Text className="item-name">Điểm đến/<span>Final destination</span> </Typography.Text>
                                        <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                            <Typography.Text style={{ fontSize: '19px', fontWeight: 600 }}>{codFds}</Typography.Text>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ borderBottom: "1px solid", width: "100%", padding: "2px 5px" }}>
                                    <Col span={11}>
                                        <Typography.Text className="item-name">4.b. Chuyến bay/<span>Flight: </span></Typography.Text>
                                    </Col>
                                    <Col span={13} style={{display: "flex", justifyContent: "center", fontWeight: 'bold'}}>
                                        <Typography.Text className="item-name">{codFltCar}{codFltNum}</Typography.Text>
                                    </Col>
                                    <Col span={11}>
                                        <Typography.Text className="item-name">4.c. Ngày bay/<span>Date: </span></Typography.Text>
                                    </Col>
                                    <Col span={13} style={{display: "flex", justifyContent: "center", fontWeight: 'bold'}}>
                                        <Typography.Text className="item-name">{datFltOri && moment(datFltOri).format("DD/MM/YYYY HH:mm")}</Typography.Text>
                                    </Col>
                                </Row>
                                <Row style={{ borderBottom: "1px solid", display: "flex", width: "100%", height: "25px", alignItems: "center", padding: "0 5px" }}>
                                    <Col span={19}>
                                        <Typography.Text className="item-name">4.d.Tổng số kiện/<span>No. of Pieces: </span> </Typography.Text>
                                    </Col>
                                    <Col span={5} style={{display: "flex", justifyContent: "center", fontWeight: 'bold'}}>
                                        <Typography.Text className="item-name">{qtyPcs}</Typography.Text>
                                    </Col>
                                </Row>
                                <Row style={{ borderBottom: "1px solid", display: "flex", width: "100%", height: "25px", alignItems: "center", padding: "0 5px" }}>
                                    <Col span={19}>
                                        <Typography.Text className="item-name">4.e.Tổng số vận đơn phụ/<span>Total of HAWBs: </span> </Typography.Text>
                                    </Col>
                                    <Col span={5} style={{display: "flex", justifyContent: "center", fontWeight: 'bold'}}>
                                        <Typography.Text className="item-name">{totalOfHawbs > 0 ? totalOfHawbs : 0}</Typography.Text>
                                    </Col>
                                </Row>
                                <Row style={{ borderBottom: "1px solid", width: "100%", padding: "5px 5px" }}>
                                    <Col span={24}>
                                        <Typography.Text className="item-name">4.f. Loại hàng/<span>Nature of goods: </span><b>{natureOfGoods}</b></Typography.Text>
                                    </Col>
                                </Row>
                                <Row style={{ borderBottom: "1px solid", width: "100%", padding: "2px 5px" }}>
                                    <Col span={24}>
                                        <div style={{ display: 'flex', flexDirection: 'column', height: "58px" }}>
                                            <Typography.Text className="item-name">5. Hình thức thanh toán/<span>Term of payment</span></Typography.Text>
                                            <div style={{ paddingLeft: "15px", display: 'flex', flexDirection: 'column', lineHeight: 1.3 }}>
                                                <div className="form-group-print">
                                                    {codPayMod === codPayModEnum.Cash  ? (
                                                        CheckBoxChecked
                                                    ) : (
                                                        CheckBoxBlank
                                                    )}
                                                    <label htmlFor="html" style={{ fontSize: "13px" }}>
                                                        Tiền mặt/<span style={{fontStyle: 'italic'}}>Cash</span>
                                                    </label>
                                                </div>
                                                <div className="form-group-print">
                                                    {codPayMod === codPayModEnum.Transfer ? (
                                                        CheckBoxChecked
                                                    ) : (
                                                        CheckBoxBlank
                                                    )}
                                                    <label htmlFor="html" style={{ fontSize: "13px" }}>Chuyển khoản/<span style={{fontStyle: 'italic'}}>Bank transfer</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%", padding: "0 5px" }}>
                                    <Col span={24} style={{margin:'3px 0'}}>
                                        <Typography.Text underline style={{fontSize: '11px'}}>Chú ý cho khách gửi hàng/<span style={{fontStyle: 'italic'}}>Note for Customer:</span></Typography.Text>
                                    </Col>
                                    <Col span={24} style={{ margin:'3px 0', display: 'flex' }}>
                                        <Typography.Text style={{fontSize: '11px', lineHeight: '101%', textAlign: 'justify'}}>
                                            a. Quá trình tiếp nhận hàng hóa chỉ kết thúc khi lô hàng hoàn tất kiểm tra an ninh hàng không./<span style={{fontStyle: 'italic'}}>The cargo acceptance is only finished when the shipment has been completely security-checked.</span>
                                        </Typography.Text>
                                    </Col>
                                    <Col span={24} style={{ margin:'3px 0', display: 'flex' }}>
                                        <Typography.Text style={{fontSize: '11px', lineHeight: '101%', textAlign: 'justify'}}>
                                            b. Đối với các lô hàng gom, khách hàng vui lòng khai báo thêm thông tin ở “Phiếu khai báo bổ sung đối với các lô hàng gom” (Mẫu QF/ED/33)./<span style={{fontStyle: 'italic'}}>For consolidated shipment, please declare details in “Declaration for Consolidation” (Form QF/ED/33).</span>
                                        </Typography.Text>
                                    </Col>
                                    <Col span={24} style={{ margin:'3px 0', display: 'flex' }}>
                                        <Typography.Text style={{fontSize: '11px', lineHeight: '101%', textAlign: 'justify'}}>
                                            c. Tờ khai gửi hàng này không có giá trị thay thế tờ khai hàng hóa hàng nguy hiểm./<span style={{fontStyle: 'italic'}}>This declaration does not replace the Shipper's Declaration for Dangerous Goods.</span>
                                        </Typography.Text>
                                    </Col>
                                    <Col span={24} style={{ margin:'3px 0', display: 'flex' }}>
                                        <Typography.Text style={{fontSize: '11px', lineHeight: '101%', textAlign: 'justify'}}>
                                            d. Khách hàng sẽ chịu trách nhiệm về tính chính xác các thông tin trên tờ khai Hướng Dẫn gửi hàng này/<span style={{fontStyle: 'italic'}}>Shipper will be responsible for the accuracy of all information on this Shipper's Instruction for despatch.</span>
                                        </Typography.Text>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid', padding: '2px 5px'}}>
                            <Typography className="item-name" style={{lineHeight: '90%'}} > 6. Hướng dẫn phục vụ/<span style={{fontStyle: 'italic'}}>Handling of information and remarks</span>. Đánh dấu (<span><CheckOutlined /></span>) vào ô thích hợp/<span style={{fontStyle: "italic"}}>Tick</span> (<span><CheckOutlined /></span>) <span style={{fontStyle: "italic"}}>where applicable:</span></Typography>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid', padding: '2px 5px', paddingBottom: '5px', fontWeight: 'bold'}}>
                            <Row style={{ lineHeight: 1.3 }}>
                                {shcPer === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Hàng dễ hư hỏng/<span style={{fontSize: "10px", fontStyle: "italic"}}>Perishable</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcVal === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Hàng quí giá/<span style={{fontSize: "10px", fontStyle: "italic"}}>Valuable</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcAvi === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Hàng động vật sống/<span style={{fontSize: "10px", fontStyle: "italic"}}>Live animals</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcDgr === "Y" && (
                                    <Col span={7}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Hàng nguy hiểm/<span style={{fontSize: "10px", fontStyle: "italic"}}>Dangerous Goods</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcOth002 === "Y" && (
                                    <Col span={7}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Hàng dược phẩm/<span style={{fontSize: "10px", fontStyle: "italic"}}>Pharmaceutical</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcBup === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>
                                                Hàng BUP
                                            </label>
                                        </div>
                                    </Col>
                                )}
                                {shcVun === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Hàng giá trị cao/<span style={{fontSize: "10px", fontStyle: "italic"}}>Vulnerable</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcVunNml === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>
                                                Vun thường
                                            </label>
                                        </div>
                                    </Col>
                                )}
                                {shcEcort === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>
                                                Áp tải
                                            </label>
                                        </div>
                                    </Col>
                                )}
                                {shcReinforce === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>
                                                Gia cố
                                            </label>
                                        </div>
                                    </Col>
                                )}
                                {shcDip === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Túi thư ngoại giao/<span style={{fontSize: "10px", fontStyle: "italic"}}>Diplomatic</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcHum === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Quan tài/<span style={{fontSize: "10px", fontStyle: "italic"}}>Human remains</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcColStr === "Y" && (
                                    <Col span={18}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>
                                                Kho lạnh/<span style={{fontSize: "10px", fontStyle: "italic"}}>Temp storage</span>
                                                <span> Yêu cầu nhiệt độ/</span><span style={{fontSize: "10px", fontStyle: "italic"}}>Temp request:</span>
                                                {shcColTemReq === tempEnum.FRO && <Typography.Text className="checkbox-label">Keep frozen (-10°C đến -18°C)</Typography.Text>}
                                                {shcColTemReq === tempEnum.COL && <Typography.Text className="checkbox-label">+2°C đến +8°C</Typography.Text>}
                                                {shcColTemReq === tempEnum.CRT && <Typography.Text className="checkbox-label">+15°C đến +25°C</Typography.Text>}
                                                {shcColTemReq === tempEnum.ERT && <Typography.Text className="checkbox-label">+2°C đến +25°C</Typography.Text>}
                                                {shcColTemReq === tempEnum.ACT && <Typography.Text className="checkbox-label">Lưu trữ trong khu vực có nhiệt độ môi trường tối thiểu cao hơn +5°C nhiệt độ cài đặt của thùng(tối ưu cao hơn +15°C đến +25°C)</Typography.Text>}
                                            </label>
                                        </div>
                                    </Col>
                                )}
                                {shcOth === "Y" && (
                                    <Col span={6}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Khác/<span style={{fontSize: "10px", fontStyle: "italic"}}>Other</span></label>
                                        </div>
                                    </Col>
                                )}
                                {shcOth001 === "Y" && (
                                    <Col span={24}>
                                        <div className="form-group-print">
                                            {CheckBoxChecked}
                                            <label htmlFor="html" style={{fontSize: "11px"}}>Yêu cầu khác/<span style={{fontStyle: 'italic'}}>Other request:</span> {shcOthReq}</label>
                                        </div>
                                    </Col>
                                )}
                                {dgrSelected && 
                                    dgrSelected.dgrItems?.filter(x => x.codTyp === 'DGR' && x.qtyPcs > 0)?.map(item => (
                                    <Col span={24} style={{ paddingTop: '2px' }}>
                                        <div key={item.id} style={{ lineHeight: 1.3, fontSize: '11px' }}>
                                            {item.qtyPcs || '......'}{'PCS '}
                                            {item.dgrTypes?.sort((a, b) => a.sorter - b.sorter)?.map((typ, idx) => (`${idx > 0 ? '/' : ''}${typ.dgrNam}${typ.namDgrExt ? `${typ.namDgrExt} ${typ.namDgrSfx}` : ''}`))}
                                        </div>
                                    </Col>
                                ))}
                                {dgrSelected && 
                                    dgrSelected.dgrItems?.filter(x => x.codTyp === 'ICE')?.map(item => (
                                    <Col span={24} style={{ paddingTop: '2px' }}>
                                        <div key={item.id} style={{ lineHeight: 1.3, fontSize: '11px' }}>
                                            {item.dgrTypes?.sort((a, b) => a.sorter - b.sorter)?.map((typ, idx) => (`${idx > 0 ? '/' : ''}${typ.dgrNam}${typ.namDgrExt ? `${typ.namDgrExt} ${typ.namDgrSfx}` : ''}`))}
                                            {' / '}{item.qtyPcs || '......'}PCS x {item.qtyWgt || '......'}KGS
                                        </div>
                                    </Col>
                                ))}
                                {dgrSelected && 
                                    dgrSelected.dgrItems?.filter(x => x.codTyp === 'PIN')?.map(item => (
                                    <Col span={24} style={{ paddingTop: '2px' }}>
                                        <div key={item.id} style={{ lineHeight: 1.3, fontSize: '11px' }}>
                                            {item.qtyPcs || '......'}{'PCS '}
                                            {item.dgrTypes?.filter(x => !['UN3481', 'UN3091', 'ELM'].includes(x.dgrNam))?.sort((a, b) => a.sorter - b.sorter)?.map((typ, idx) => (`${idx > 0 ? '/' : ''}${typ.dgrNam}${typ.namDgrExt ? `${typ.namDgrExt} ${typ.namDgrSfx}` : ''}`))}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid', padding: '2px 5px'}}>
                            <Row>
                                <Col span={24} style={{display: 'flex', width: '100%', alignItems: 'center' }}>
                                    <Typography.Text style={{ fontSize: '11px', lineHeight: '101%', textAlign: 'justify' }}>
                                        Tôi xác nhận đã khai báo theo đúng tính chất thực tế của hàng hóa. Nếu có hàng nguy hiểm, hàng động vật sống, hàng mau hỏng sẽ được khai báo đúng quy định và thể lệ vận chuyển của IATA và quy định Hãng bay /
                                        <span style={{ fontSize: "11px", fontStyle: 'italic' }}>I certify that the content of this consignment is properly identified by its nature. Insofar as any part of the consignment contains dangerous goods, Live animal, Perishable such part is in proper condition for carriage by air according to IATA's and Airlines’ Regulation.</span>
                                    </Typography.Text>
                                </Col>
                                <Col span={11} style={{paddingLeft: '5px', paddingTop: '5px'}}>
                                    <Row>
                                        <Col span={16}>
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <Typography.Text className="item-name">Tên đầy đủ, chữ ký người gửi hàng:</Typography.Text>
                                                <Typography.Text className="item-name" style={{ fontStyle: 'italic'}}>Fullname, Shipper's signature:</Typography.Text>
                                            </div>
                                        </Col>
                                        <Col span={8} style={{display: "flex", justifyContent: "left"}}>
                                            <Typography.Text className="item-name">{shpRegNam}/{usrCre?.toLocaleUpperCase()}</Typography.Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={5} style={{paddingTop: '5px'}}>
                                    <Row>
                                        <Col span={7}>
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <Typography.Text className="item-name">Số ĐT:</Typography.Text>
                                                <Typography.Text className="item-name" style={{ fontStyle: 'italic'}}>Tel:</Typography.Text>
                                            </div>
                                        </Col>
                                        <Col span={17} style={{display: "flex", justifyContent: "center"}}>
                                            <Typography.Text className="item-name">{shpRegTel}</Typography.Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8} style={{paddingTop: '5px'}}>
                                    <Row>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}>
                                                    <Typography.Text className="item-name">Số CMND/<span>ID No: </span></Typography.Text>
                                                </Col>
                                                <Col span={12} style={{display: "flex", justifyContent: "center"}}>
                                                   <Typography.Text className="item-name">{shpRegIdx}</Typography.Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}>
                                                    <Typography.Text className="item-name">Ngày-giờ/<span>Date-Time: </span></Typography.Text>
                                                </Col>
                                                <Col span={12} style={{display: "flex", justifyContent: "center"}}>
                                                   <Typography.Text className="item-name">{shpRegDat && moment(shpRegDat).format("DD/MM/YYYY HH:mm")}</Typography.Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid', padding: '0 5px'}}>
                            <Typography.Text className="item-name" style={{fontWeight: 600}}>
                                II. Phần dành cho nhân viên Tiếp Nhận/<span style={{fontStyle: 'italic'}}>Complete by Acceptance</span>
                            </Typography.Text>
                            {dgrSelected && 
                                dgrSelected.dgrItems?.filter(x => x.codTyp === 'DGR' && (x.codShc || x.codEgr || x.desRmk))?.sort((a, b) => a.sorter - b.sorter)?.map(item => (
                                <Row style={{ fontSize: '11px' }}>
                                    <Col span={10}>
                                        {`${item.codShc || ''}${item.codErg ? '/' + item.codErg : ''}`}
                                    </Col>
                                    <Col span={14}>
                                        {item.desRmk && (<div dangerouslySetInnerHTML={{ __html: item.desRmk?.replace(/\n/g, "<br />")}} />)}
                                    </Col>
                                </Row>
                            ))}
                            {dgrSelected && 
                                dgrSelected.dgrItems?.filter(x => x.codTyp === 'ICE' && (x.codShc || x.codEgr || x.desRmk))?.sort((a, b) => a.sorter - b.sorter)?.map(item => (
                                <Row style={{ fontSize: '11px' }}>
                                    <Col span={10}>
                                        {`${item.codShc || 'ICE'}${item.codErg ? '/' + item.codErg : ''}`}
                                    </Col>
                                    <Col span={14}>
                                        {item.desRmk && (<div dangerouslySetInnerHTML={{ __html: item.desRmk?.replace(/\n/g, "<br />")}} />)}
                                    </Col>
                                </Row>
                            ))}
                            {dgrSelected && 
                                dgrSelected.dgrItems?.filter(x => x.codTyp === 'PIN' && (x.codShc || x.codEgr || x.desRmk))?.sort((a, b) => a.sorter - b.sorter)?.map(item => (
                                    <Row style={{ fontSize: '11px' }}>
                                        <Col span={10}>
                                        {`${item.codShc || ''}${item.codErg ? '/' + item.codErg : ''}`}
                                        </Col>
                                        <Col span={14}>
                                            {item.desRmk && (<div dangerouslySetInnerHTML={{ __html: item.desRmk?.replace(/\n/g, "<br />")}} />)}
                                        </Col>
                                    </Row>
                            ))}
                            <Row style={{ fontSize: '11px' }}>
                                <Col span={4}>
                                    {aviSelected?.specHdlCod}
                                </Col>
                                <Col span={20}>
                                    {aviSelected?.desRmk && (<div dangerouslySetInnerHTML={{ __html: aviSelected?.desRmk?.replace(/\n/g, "<br />")}} />)}
                                </Col>
                            </Row>
                            <Row style={{ fontSize: '11px' }}>
                                <Col span={24}>
                                    {desRmk && (<div dangerouslySetInnerHTML={{ __html: desRmk?.replace(/\n/g, "<br />")}} />)}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid', padding: '5px'}}>
                            <Row>
                                <Col span={24} style={{height: "10px", marginBottom: '5px'}}>
                                    <Typography.Text className="item-name" style={{lineHeight: "99%"}}>
                                        7. Kích thước lô hàng (dài x rộng x cao)/ <span>Dimensions (L x W x H):</span>
                                        { expCarDim && expCarDim.length > 12 ? 
                                            (
                                                <Typography.Text className="item-name" style={{color: 'red', fontStyle: 'normal', lineHeight: "99%"}}> Xem danh sách đính kèm</Typography.Text>
                                            ) 
                                            : 
                                            (
                                                <></>
                                            )
                                        }
                                    </Typography.Text>
                                </Col>
                                { expCarDim && expCarDim.length && expCarDim.length <= 12 ? (
                                    <>
                                        <Col span={6}>
                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[0] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[0].qtyDimLen}x${expCarDim?.[0].qtyDimWid}x${expCarDim?.[0].qtyDimHgt})${expCarDim?.[0].qtyDimPcs} = ${expCarDim?.[0].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[1] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[1].qtyDimLen}x${expCarDim?.[1].qtyDimWid}x${expCarDim?.[1].qtyDimHgt})${expCarDim?.[1].qtyDimPcs} = ${expCarDim?.[1].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[2] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[2].qtyDimLen}x${expCarDim?.[2].qtyDimWid}x${expCarDim?.[2].qtyDimHgt})${expCarDim?.[2].qtyDimPcs} = ${expCarDim?.[2].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                        </Col>
                                        <Col span={6}>
                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[3] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[3].qtyDimLen}x${expCarDim?.[3].qtyDimWid}x${expCarDim?.[3].qtyDimHgt})${expCarDim?.[3].qtyDimPcs} = ${expCarDim?.[3].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[4] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[4].qtyDimLen}x${expCarDim?.[4].qtyDimWid}x${expCarDim?.[4].qtyDimHgt})${expCarDim?.[4].qtyDimPcs} = ${expCarDim?.[4].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[5] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[5].qtyDimLen}x${expCarDim?.[5].qtyDimWid}x${expCarDim?.[5].qtyDimHgt})${expCarDim?.[5].qtyDimPcs} = ${expCarDim?.[5].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                        </Col>
                                        <Col span={6}>
                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[6] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[6].qtyDimLen}x${expCarDim?.[6].qtyDimWid}x${expCarDim?.[6].qtyDimHgt})${expCarDim?.[6].qtyDimPcs} = ${expCarDim?.[6].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[7] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[7].qtyDimLen}x${expCarDim?.[7].qtyDimWid}x${expCarDim?.[7].qtyDimHgt})${expCarDim?.[7].qtyDimPcs} = ${expCarDim?.[7].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[8] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[8].qtyDimLen}x${expCarDim?.[8].qtyDimWid}x${expCarDim?.[8].qtyDimHgt})${expCarDim?.[8].qtyDimPcs} = ${expCarDim?.[8].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                        </Col>
                                        <Col span={6}>
                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[9] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[9].qtyDimLen}x${expCarDim?.[9].qtyDimWid}x${expCarDim?.[9].qtyDimHgt})${expCarDim?.[9].qtyDimPcs} = ${expCarDim?.[9].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[10] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[10].qtyDimLen}x${expCarDim?.[10].qtyDimWid}x${expCarDim?.[10].qtyDimHgt})${expCarDim?.[10].qtyDimPcs} = ${expCarDim?.[10].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}

                                            {expCarDim && expCarDim.length > 0 && expCarDim?.[11] ?
                                                (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div>
                                                                <Typography.Text className="item-name">{`(${expCarDim?.[11].qtyDimLen}x${expCarDim?.[11].qtyDimWid}x${expCarDim?.[11].qtyDimHgt})${expCarDim?.[11].qtyDimPcs} = ${expCarDim?.[11].qtyDimWgt}`}</Typography.Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <Typography.Text />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                        </Col>
                                    </>
                                ) : (
                                    <></>
                                )}     
                            </Row>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid'}}>
                            <Row>
                                <Col span={8} style={{borderRight: '1px solid'}}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2px 0' }}>
                                        <Typography.Text className="item-name" style={{ lineHeight: '101%' }}>
                                            7.a. Trọng lượng theo thể tích
                                        </Typography.Text>
                                        <Typography.Text className="item-name" style={{ lineHeight: '101%', fontStyle: 'italic' }}>
                                            Volume weight (kg)
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={8} style={{borderRight: '1px solid'}}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2px 0' }}>
                                        <Typography.Text className="item-name" style={{ lineHeight: '101%' }}>
                                            7.b. Trọng lượng 
                                        </Typography.Text>
                                        <Typography.Text className="item-name" style={{ lineHeight: '101%', fontStyle: 'italic' }}>
                                            Gross weight (kg)
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2px 0' }}>
                                        <Typography.Text className="item-name" style={{ lineHeight: '101%' }}>
                                            7.c. Trọng lượng tính cước
                                        </Typography.Text>
                                        <Typography.Text className="item-name" style={{ lineHeight: '101%', fontStyle: 'italic' }}>
                                            Chargeable weight (kg)
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid'}}>
                            <Row>
                                <Col span={8} style={{borderRight: '1px solid', height: '19px'}}>
                                    <div style={{ width: '100%', height: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                                        <Typography.Text className="item-name">
                                            {expCarWgt && expCarWgt.length > 0 && expCarWgt[0].volWgt}
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={8} style={{borderRight: '1px solid', height: '19px'}}>
                                    <div style={{ width: '100%', height: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                                       <Typography.Text className="item-name">
                                            {expCarWgt && expCarWgt.length > 0 && expCarWgt[0].wgtGrs}
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={8} style={{height: '19px'}}>
                                    <div style={{ width: '100%', height: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                                        <Typography.Text className="item-name">
                                            {/* {expCarWgt && expCarWgt.length > 0 && (expCarWgt[0]?.volWgt > expCarWgt[0]?.wgtGrs) ? expCarWgt[0]?.volWgt : expCarWgt[0]?.wgtGrs} */}
                                            { expCarWgt && expCarWgt.length > 0 ? (
                                                <>
                                                    {(expCarWgt[0]?.volWgt > expCarWgt[0]?.wgtGrs) ? expCarWgt[0]?.volWgt : expCarWgt[0]?.wgtGrs}
                                                </>
                                            ) :(
                                                <></>
                                            ) }
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{borderTop: '1px solid'}}>
                            <Row>
                                <Col span={16} style={{padding: '5px 5px 0',borderRight: '1px solid'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <Typography.Text className="item-name">
                                            Tên đầy đủ, chữ ký nhân viên tiếp nhận: {acpStfNam}
                                        </Typography.Text>
                                        <Typography.Text className="item-name" style={{fontStyle:'italic'}}>
                                            Full Name, Acceptance staff's signature:
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={8} style={{padding: '5px'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <Typography.Text className="item-name" name="acpStfName">
                                            Ngày giờ tiếp nhận: {acpDat && acpStfNam && moment(acpDat).format("DD/MM/YYYY HH:mm")} 
                                        </Typography.Text>
                                        <Typography.Text className="item-name" style={{fontStyle:'italic'}}>
                                            Date-Time:
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col span={24} style={{borderTop: '1px solid', display: "flex", flexDirection: "column", width: "100%", justifyContent: "center"}}>
                            <Row style={{paddingTop: '5px', paddingLeft: '5px'}}>
                                <Col span={24}>
                                    <Typography.Text style={{fontSize: '10px'}}>
                                        GIAO/DISTRIBUTION
                                    </Typography.Text>
                                </Col>
                            </Row>
                            <Row style={{paddingLeft: '5px', paddingBottom: '5px'}}>
                                <Col span={12}>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                        <Typography.Text style={{fontSize: '10px'}}>
                                            Bản 01(màu trắng): Hãng hàng không /<span style={{fontStyle: 'italic'}}>01 <sup>st</sup> copy(white) - Airlines.</span>
                                        </Typography.Text>
                                        <Typography.Text style={{fontSize: '10px'}}>
                                            Bản 02(màu vàng): Kho xuất  /<span style={{fontStyle: 'italic'}}>02 <sup>nd</sup> copy(White) - Export Docs.</span>
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                        <Typography.Text style={{fontSize: '10px'}}>
                                            Bản 03(màu hồng): Thu ngân /<span style={{fontStyle: 'italic'}}>03 <sup>rd</sup> copy(pink) - Finance.</span>
                                        </Typography.Text>
                                        <Typography.Text style={{fontSize: '10px'}}>
                                            Bản 04(màu xanh): Người gửi hàng  /<span style={{fontStyle: 'italic'}}>04 <sup>th</sup> copy(Blue) - Shipper.</span>
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                        </Col> */}
                        <Col span={24} style={{borderTop: '1px solid'}}>
                            <Row>
                                <Col span={16} style={{padding: '5px 5px 0',borderRight: '1px solid'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <Typography.Text className="item-name">
                                            Tên đầy đủ, chữ ký nhân viên tiếp nhận DG: {dgrAcpStfNam}
                                        </Typography.Text>
                                        <Typography.Text className="item-name" style={{fontStyle:'italic'}}>
                                            Full Name, Acceptance staff's signature:
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={8} style={{padding: '5px'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <Typography.Text className="item-name">
                                            Ngày giờ tiếp nhận: {dgrAcpDat && moment(dgrAcpDat).format("DD/MM/YYYY HH:mm")}
                                        </Typography.Text>
                                        <Typography.Text className="item-name" style={{fontStyle:'italic'}}>
                                            Date-Time:
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row justify="center">
                        <Col span={23} style={{ width: "100%", height: "100%", border: "1px solid", padding: "10px", marginTop: "10px", fontWeight:"600" }}>
                            <Checkbox style={{ marginLeft: "20px" }}>Tôi đồng ý nội dung sau</Checkbox>
                            <div>
                                <p style={{marginBottom:"3px"}}>- Tôi cam kết các nội dung khai báo trên là chính xác và chịu hoàn toàn trách nhiệm nếu thông tin khai báo sai lệch so với thực tế dẫn đến hàng hóa bị offload/ lạc tuyến/ phục vụ sai quy định của nhà chức trách/ hãng vận chuyển</p>
                                <p style={{marginBottom:"3px"}}>- Bảng khai báo trên phù hợp với nội dung thể hiện trong Phiếu hướng dẫn gửi hàng của TCS(mẫu QF/ED/31) và được hoàn tất trước khi hàng hóa được đưa vào kho hàng xuất TCS</p>
                            </div>
                        </Col>
                    </Row> */}
                    {/* <Row className="awb-tab-title-wrapper">
                        <Col span={24} className="awb-tab-title">
                            <h5>
                                {checkLanguage === "vi"
                                    ? "QUÝ KHÁCH VUI LÒNG XUẤT TRÌNH MÃ ĐĂNG KÝ/QR TẠI CỔNG AN NINH TCS"
                                    : " Please provide the registration code/QR code at the TCS security gate"}
                            </h5>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <QRCode value={`${this.props.QR}`} />
                    </Row> */}
                </div>

            </div>
            {desRmk001 && (
                <>
                    <div style={{ pageBreakBefore: 'always' }} />
                    <Row justify="center" style={{ fontSize: '16px', fontWeight: 'bold', width: '100%', marginTop: 20 }}>
                        <Col span={24}>
                            <Row style={{ marginBottom: "0px", fontWeight: "600", display: 'flex', justifyContent: 'center'}}>
                                <Col style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px'}}>
                                    <Typography.Text style={{ fontSize: '18px', fontWeight: 'bold'}}>THÔNG BÁO ĐÍNH KÈM / ATTACHED NOTICE</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ fontSize: '12px', width: '100%', marginTop: 20 }}>
                        <Col span={24}>
                            {desRmk001 && (<div dangerouslySetInnerHTML={{ __html: desRmk001?.replace(/\n/g, "<br />")}} />)}
                        </Col>
                    </Row>
                </>
            )}
            {expCarDim?.length > 12 && expCarWgt?.length > 0 && (
                <>
                    <div style={{ pageBreakBefore: 'always' }} />
                    <DmsSizeToPrint dataExport={{dmsSizes: expCarDim, wgt: expCarWgt[0], shpRegNam, acpStfNam}}/>
                </>
            )}
            {shcConsol === 'Y' && expConsol && (
                <>
                    <div style={{ pageBreakBefore: 'always' }} />
                    <ExpDeclConsolPrint expConsol={expConsol} />
                </>
            )}
            </div>
        );
    }
}
