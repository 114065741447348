import React, { useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  notification,
  Select,
  Checkbox,
  Spin,
  Divider,
  Space,
  InputNumber,
  Typography,
  Modal,
  DatePicker,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/en-au";
import { PlusOutlined, LoadingOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import QRCode from "qrcode.react";
import RegisterModal from "./registerModal/RegisterModal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CancelEsid,
  CheckExistsTaxCode,
  CreateEsidExport,
  EsidCheckAwb,
  ExpWebCheckLoadLst,
  GetAllAirports,
  GetEsidCtcCmpList,
  UpdateEsidExp,
} from "../config/esidExportApi";
import { errorHandling } from "../config/AuthIntercept";
import {
  setAirportList,
  setEsidExpSelected,
  setEsidCtcCmps,
  setTabEsidVisibility,
  setExpBilChgLst,
  setModalEsidVisibility,
  setExpConsol,
  setExpConsolDtlList,
  setExpConsolRegServices,
  setExpConsolEdit,
} from "../actions/esidExportAction";
import {
  AptTypEnum,
  CodCtcTypEnum,
  esidExpTabsEnum,
  ModalName,
} from "../constant/enumerations";
import { ModalCutOffTime } from "./tracking/RegistryInvModal/ModalCutOffTime";
import { RegistryExportPrint } from "./RegistryExportPrint";
import { useReactToPrint } from "react-to-print";
import TabExpDeclDgr from "./tracking/ExpDeclDgr/TabExpDeclDgr";
import TabExpCertLivAnm from "./tracking/CertLivAnm/TabExpCertLivAnm";
import { setDisableEditLivAnmsFormDecl, setIsVisableEditLivAnmButton, setLivAnms, setLivAnmSelectedToEdit, setLivAnmsSelectedValue } from "../actions/livAnmAction";
import { setDgrGoods, setDgrSelectedToEdit, setDgrSelectedValue, setDisableEditDrgGood, setIsVisableEditDrgGoodButton } from "../actions/dgrGoodsAction";
import UNSelect from "./UNSelect";
import tempReqData from "../constant/tempDropdown";
import { removeAccents } from "../utils/utils";

const { Option } = Select;

const TabRegistryExportInformation = () => {
  const dispatch = useDispatch();
  const { cmpList } = useSelector((state) => state.esidExport);
  const { airports } = useSelector((state) => state.airport);
  const [form] = useForm();
  const [regisForm] = useForm();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [titleModal, setTitleModal] = useState(CodCtcTypEnum.Shipper);
  const [itemData, setItemData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const { t } = useTranslation("common");
  const dateFormat = "DD/MM/YYYY HH:mm";
  const { TextArea } = Input;
  const { signedIn } = useSelector((state) => state.userAwb);
  const history = useHistory();
  const location = useLocation();
  const [flightNo, setFlightNo] = useState("");
  const [flightDate, setFlightDate] = useState(moment());
  const [visibleFlightTable, setVisibleFlightTable] = useState(false);
  const today = new Date();
  const esidExport = useSelector((state) => state.esidExport.esidExpSelected);
  const [QR, setQR] = useState("");
  const [numberQR, setNumberQR] = useState();
  const [errors, setErrors] = useState({});
  const [QRToPrint, setQRToPrint] = useState();
  const [isCheckTempStorageCheckbox, setIsTempStorageCheckbox] = useState(false);
  const [isOpenDrgGoodsDeclModal, setIsOpenDrgGoodsDeclModal] = useState(false);
  const [isOpenConfirmCloseDrgModal, setIsOpenConfirmCloseDrgModal] = useState(false);
  const [isOpenLivAnmGoodsDeclModal, setIsOpenLivAnmGoodsDeclModal] = useState(false);
  const [isOpenConfirmCloseLivAnmModal, setIsOpenConfirmCloseLivAnmModal] = useState(false);
  const dgrSelectedValue = useSelector((state) => state.dgrGoods.dgrSelectedValue);
  const dgrGoods = useSelector((state) => state.dgrGoods.dgrGoods);
  const livAnmsSelectedValue = useSelector((state) => state.livAnms.livAnmsSelectedValue);
  const livAnms = useSelector((state) => state.livAnms.livAnms);
  const [codeAwb, setCodeAwb] = useState("");
  const [isUNSelectOpen, setIsUNSelectOpen] = useState(false);
  const [isEditReqOth, setIsEditReqOth] = useState(false);
  
  const mainRow = useRef();
  const footerRow = useRef();

  const infoShiper = {
    title: CodCtcTypEnum.Shipper,
    id: "shipperId",
    name: "nameShp",
    address: "addressShp",
    email: "emailShp",
    tel: "telShp",
    fax: "faxShp",
    vat: "vatShp",
  };
  const infoArgent = {
    title: CodCtcTypEnum.Agent,
    id: "agentId",
    name: "nameAgt",
    address: "addressAgt",
    email: "emailAgt",
    tel: "telAgt",
    fax: "faxAgt",
    vat: "vatAgt",
  };
  const infoConsignee = {
    title: CodCtcTypEnum.Consignee,
    id: "consigneeId",
    name: "nameCne",
    address: "addressCne",
    email: "emailCne",
    tel: "telCne",
    fax: "faxCne",
    vat: "vatCne",

    notifyId: "notifyId",
    notify: "nameNtf",
    address2: "addressNtf",
    email2: "emailNtf",
    tel2: "telNtf",
    fax2: "faxNtf",
    desRmk001: "desRmk001",
  };

  const GetData = async () => {
    if (!signedIn) {
      return;
    }
    try {
      setLoadingData(true);
      // lấy lại dữ liệu CMP
      // if (!cmpList || cmpList?.length === 0) {
      const response = await GetEsidCtcCmpList();
      dispatch(setEsidCtcCmps(response.data));
      // }
      if (!airports || airports?.length === 0) {
        const res = await GetAllAirports();
        dispatch(setAirportList(res.data));
      }
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      errorHandling(error, history, location.pathname);
    }
  };

  useEffect(() => {
    GetData();
    // eslint-disable-next-line
    // const { data } = await GetEsidExportById(628);
    // dispatch(setEsidExpSelected(data))
    // eslint-disable-next-line 
  }, []);

  const SetDefaultOrigin = () => {
    const sgnApt = airports.find((x) => x.codApt === "SGN");
    if (sgnApt) {
      form.setFieldsValue({
        codOri: sgnApt.codApt,
        originName: sgnApt.namApt,
      });
    }
  };

  useEffect(() => {
    if (!signedIn)
      history.push("/AwbLogin", {
        from: { pathname: location.pathname || "/" },
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (flightNo !== "") {
      form.setFieldsValue({
        flightNo: flightNo,
        codFltCar: flightNo.substring(0, 2),
        codFltNum: flightNo.substring(2, flightNo.length),
        datFltOri: flightDate,
      });
    }
    // eslint-disable-next-line
  }, [flightNo, flightDate]);

  const createNewRegis = () => {
    window.scrollTo({ left: 0, top: 350 });
    form.resetFields();
    dispatch(setEsidExpSelected(null));
    dispatch(setDgrSelectedValue(null));
    dispatch(setDgrGoods([]));
    dispatch(setDgrSelectedToEdit(null));
    
    dispatch(setLivAnmsSelectedValue(null));
    dispatch(setExpConsol(null));
    dispatch(setExpConsolDtlList(null));
    dispatch(setExpConsolRegServices(null));
    dispatch(setExpConsolEdit(null));

    setErrors({});
    setNumberQR(null);
    setQR(null);
    setFlightNo('');
    setFlightDate(null);
    SetDefaultOrigin();
    setIsTempStorageCheckbox(false);
    setIsPharma(false);
  };

  const createQR = (value) => {
    setQR(<QRCode value={`${value}`} />);
  };

  const validateErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((k) => {
      if (errors[k]) {
        hasError = true;
      }
    });
    return hasError;
  };

  useEffect(() => {
    if (esidExport && airports) {
      const ori = airports.find((x) => x.codApt === esidExport.codOri);
      const fds = airports.find((x) => x.codApt === esidExport.codFds);
      const ctyfds = airports.find((x) => x.codCty === esidExport.codFds);
      form.setFieldsValue({
        ...esidExport,
        flightNo: `${esidExport.codFltCar}${esidExport.codFltNum}`,
        datFltOri: moment(esidExport.datFltOri),
        originName: ori?.namApt,
        destinationName: fds?.namApt || ctyfds?.namCty,
        natureOfGoods: esidExport.natureOfGoods?.toLocaleUpperCase(),
        shcPer: esidExport.shcPer === "Y" ? true : false,
        shcVal: esidExport.shcVal === "Y" ? true : false,
        shcAvi: esidExport.shcAvi === "Y" ? true : false,
        shcDgr: esidExport.shcDgr === "Y" ? true : false,
        shcBup: esidExport.shcBup === "Y" ? true : false,
        shcVun: esidExport.shcVun === "Y" ? true : false,
        shcVunNml: esidExport.shcVunNml === "Y" ? true : false,
        shcEcort: esidExport.shcEcort === "Y" ? true : false,
        shcReinforce: esidExport.shcReinforce === "Y" ? true : false,
        shcConsol: esidExport.shcConsol === "Y" ? true : false,
        shcHum: esidExport.shcHum === "Y" ? true : false,
        shcDip: esidExport.shcDip === "Y" ? true : false,
        shcColStr: esidExport.shcColStr === "Y" ? true : false,
        agreeConfirm: esidExport.agreeConfirm === "Y" ? true : false,
        shcOth: esidExport.shcOth === "Y" ? true : false,
        shcOth001: esidExport.shcOth001 === "Y" ? true : false,
        shcOth002: esidExport.shcOth002 === "Y" ? true : false,
        shcCod002: esidExport.shcCod002 === "TEC" ? true : false,
      });
      const { shipper, consignee } = esidExport;
      if (shipper) {
        form.setFieldsValue({
          shipperId: shipper.ctcId,
          title: shipper.codCtcTyp,
          nameShp: shipper.namCtc,
          addressShp: shipper.desAddStt,
          vatShp: shipper.codTaxCmp,
          emailShp: shipper.desEmail,
          telShp: shipper.desPhone,
          faxShp: shipper.desFax,
        });
      }
      if (consignee) {
        form.setFieldsValue({
          consigneeId: consignee.ctcId,
          title: consignee.codCtcTyp,
          nameCne: consignee.namCtc,
          addressCne: consignee.desAddStt,
          vatCne: consignee.codTaxCmp,
          emailCne: consignee.desEmail,
          telCne: consignee.desPhone,
          faxCne: consignee.desFax,
        });
      }
      if (esidExport.agent) {
        const { agent } = esidExport;
        checkExistsTaxCode(agent.codTaxCmp);
        form.setFieldsValue({
          agentId: agent.ctcId,
          title: agent.codCtcTyp,
          nameAgt: agent.namCtc,
          addressAgt: agent.desAddStt,
          vatAgt: agent.codTaxCmp,
          emailAgt: agent.desEmail,
          telAgt: agent.desPhone,
          faxAgt: agent.desFax,
        });
      }
      if (esidExport.notify) {
        const { notify } = esidExport;
        form.setFieldsValue({
          notifyId: notify.ctcId,
          title: notify.codCtcTyp,
          nameNtf: notify.namCtc,
          addressNtf: notify.desAddStt,
          vatNtf: notify.codTaxCmp,
          emailNtf: notify.desEmail,
          telNtf: notify.desPhone,
          faxNtf: notify.desFax,
          desRmk001: esidExport.desRmk001
        });
      }
      setIsPharma(esidExport.shcOth002 === 'Y');
      setIsTempStorageCheckbox(esidExport.shcColStr === 'Y');
      setIsEditReqOth(esidExport.shcOth001 === 'Y');
      setFlightNo(`${esidExport.codFltCar}${esidExport.codFltNum}`);
      setFlightDate(moment(esidExport.datFltOri));
      const qrCodeStr = `${esidExport.codAwbPfx}${esidExport.codAwbNum}`; //`${EsidTypeEnum.EsidExp}#${esidExport.tcsCodRefNum}`;
      setNumberQR(esidExport.tcsCodRefNum);
      setQRToPrint(qrCodeStr);
      createQR(qrCodeStr);
      const codeAwb = `${esidExport.codAwbPfx+esidExport.codAwbNum}`
      setCodeAwb(codeAwb)
      const selectedOption = tempReqData.find(x => x.code === esidExport?.shcColTemReq);
      //shcColTemReq can be null
      if(selectedOption){
        form.setFieldsValue({
          // shcColTemReq: esidExport.shcColTemReq,
          tempTitle: selectedOption.title
        })
      }
    } else {
      form.resetFields();
      SetDefaultOrigin();
    }
    // eslint-disable-next-line
  }, [esidExport, airports]);

  const nextStep = (esid, currentStep) => {
    if (esid?.shcDgr === 'Y' && currentStep === esidExpTabsEnum.ExpRegis) {
      handleDeclDrg();
      return;
    }
    // if (esid?.shcAvi === 'Y' && 
    //   (currentStep === esidExpTabsEnum.ExpRegis || currentStep === esidExpTabsEnum.Dgr)) {
    //   handleCertLivAnm();
    //   return;
    // }
    if (esid?.shcConsol === 'Y' && 
      (currentStep === esidExpTabsEnum.ExpRegis || 
        currentStep === esidExpTabsEnum.Dgr || currentStep === esidExpTabsEnum.LivAnm)) {
      handleDeclConsol();
      return;
    }
    handleCarDim();
  }

  const handleFinish = async (values, isSaveEsid = false) => {
    console.log(values)
    if (validateErrors()) {
      notification.error({
        message: "Oops!",
        description: t("feedback.errorNotify"),
      });
      return;
    }
    if (taxCodeMsg) {
      Modal.error({
        title: 'Thông báo',
        content: taxCodeMsg,
        onOk: () => {
          return;
        }
      });
    }
    const currentDate = new Date();
    const shipper = cmpList.find((x) => x.id === values.shipperId);
    const consignee = cmpList.find((x) => x.id === values.consigneeId);
    const data = {
      shipper: {
        ctcId: values.shipperId,
        codCtcTyp: CodCtcTypEnum.Shipper,
        namCtc: shipper?.namCtc,
        desAddStt: values.addressShp,
        codTaxCmp: values.vatShp,
        desEmail: values.emailShp,
        desPhone: values.telShp,
        desFax: values.faxShp,
        codSts: values.agreeConfirm ? "Y" : "N",
      },
      consignee: {
        ctcId: values.consigneeId,
        codCtcTyp: CodCtcTypEnum.Consignee,
        namCtc: consignee?.namCtc,
        desAddStt: values.addressCne,
        codTaxCmp: values.vatCne,
        desEmail: values.emailCne,
        desPhone: values.telCne,
        desFax: values.faxCne,
        codSts: values.agreeConfirm ? "Y" : "N",
      },
      codAwbPfx: values.codAwbPfx,
      codAwbNum: values.codAwbNum,
      codAwbSfx: "P",
      codOri: values.codOri,
      codFds: values.codFds,
      codFltCar: values.flightNo.substring(0, 2),
      codFltNum: values.flightNo.substring(2, values.flightNo.length),
      datFltOri: new Date(values.datFltOri).toISOString(), //moment(values.datFltOri).toISOString(),
      qtyPcs: values.qtyPcs,
      CodTypWgt: "K",
      totalOfHawbs: values.totalOfHawbs,
      natureOfGoods: values.natureOfGoods?.toLocaleUpperCase(),
      shcPer: values.shcPer ? "Y" : "N",
      shcVal: values.shcVal ? "Y" : "N",
      shcAvi: values.shcAvi ? "Y" : "N",
      shcDgr: values.shcDgr ? "Y" : "N",
      shcBup: values.shcBup ? "Y" : "N",
      shcVun: values.shcVun ? "Y" : "N",
      shcVunNml: values.shcVunNml ? "Y" : "N",
      shcEcort: values.shcEcort ? "Y" : "N",
      shcReinforce: values.shcReinforce ? "Y" : "N",
      shcConsol: values.shcConsol ? "Y" : "N",
      shcHum: values.shcHum ? "Y" : "N",
      shcDip: values.shcDip ? "Y" : "N",
      shcColStr: values.shcColStr ? "Y" : "N",
      shcColTemReq: values.shcColTemReq,
      shcOth: values.shcOth ? "Y" : "N",
      shcOth001: values.shcOth001 || values.shcOthReq ? "Y" : "N",
      shcOth002: values.shcOth002 ? "Y" : "N",
      dgChk: values.shcOth002 ? "Y" : "N", // chuyển cho tổ DG kiểm tra
      shcCod002: values.shcCod002 ? "TEC" : "TCS",
      shcOthReq: values.shcOthReq,
      codPayMod: values.codPayMod,
      agreeConfirm: values.agreeConfirm ? "Y" : "N",
      shpRegDat: esidExport?.ShpRegDat || currentDate.toISOString(),
      shpRegIdx: values.shpRegIdx,
      shpRegNam: values.shpRegNam,
      shpRegAdr: '',
      shpRegTel: values.shpRegTel,
      shpRegEml: '',
      shpRegSignYn: values.agreeConfirm ? "Y" : "N",
      acpDat: currentDate.toISOString(),
      codSts: "NY",
      shcCod001: "VKD", // The tich lam tron so le bang 0.5 neu <= 0.5 va bang 1 neu > 0.5
      desRmk001: values.desRmk001
    };
    if (values.agentId) {
      const agt = cmpList.find((x) => x.id === values.agentId);
      data.agent = {
        ctcId: values.agentId,
        codCtcTyp: CodCtcTypEnum.Agent,
        namCtc: agt?.namCtc,
        desAddStt: values.addressAgt,
        codTaxCmp: values.vatAgt,
        desEmail: values.emailAgt,
        desPhone: values.telAgt,
        desFax: values.faxAgt,
        codSts: values.agreeConfirm ? "Y" : "N",
      };
    }
    if (values.notifyId) {
      const ntf = cmpList.find((x) => x.id === values.notifyId);
      data.notify = {
        ctcId: values.notifyId,
        codCtcTyp: CodCtcTypEnum.Notify,
        namCtc: ntf?.namCtc,
        desAddStt: values.addressNtf || ' ',
        desEmail: values.emailNtf,
        desPhone: values.telNtf,
        desFax: values.faxNtf,
        codSts: values.agreeConfirm ? "Y" : "N",
      };
    }

    try {
      setLoading(true);
      const response = isSaveEsid ? 
        await UpdateEsidExp({
          ...esidExport,
          ...data,
          agent: {
            ...data.agent,
            codAwbNum: data.codAwbNum,
            codAwbPfx: data.codAwbPfx,
            id: esidExport.agent?.id,
            esidId: esidExport.id,
          },
          consignee: {
            ...data.consignee,
            codAwbNum: data.codAwbNum,
            codAwbPfx: data.codAwbPfx,
            id: esidExport.consignee?.id,
            esidId: esidExport.id,
          },
          shipper: {
            ...data.shipper,
            codAwbNum: data.codAwbNum,
            codAwbPfx: data.codAwbPfx,
            id: esidExport.shipper?.id,
            esidId: esidExport.id,
          },
          notify: values.notifyId ? {
            ...data.notify,
            codAwbNum: data.codAwbNum,
            codAwbPfx: data.codAwbPfx,
            id: esidExport.notify?.id,
            esidId: esidExport.id,
          } : undefined,
          shpRegDat: esidExport?.shpRegDat || currentDate.toISOString(),
          expCarDim: esidExport?.expCarDim || [],
          expCarWgt: esidExport?.expCarWgt || [],
        }) : 
        await CreateEsidExport(data);
      if (response.data) {
        const { data } = response;
        dispatch(setEsidExpSelected(data));
        nextStep(data, esidExpTabsEnum.ExpRegis);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandling(error, history, location.pathname);
    }
  };

  const handleSaveEsidExp = async () => {
    const values = await form.validateFields();
    await handleFinish(values, true);
  }

  // eslint-disable-next-line
  const handleCancelEsid = async () => {
    if (esidExport?.id) {
      try {
        setLoading(true);
        await CancelEsid(esidExport.id);
        const esid = JSON.parse(JSON.stringify(esidExport));
        esid.codSts = 'XX';
        dispatch(setEsidExpSelected(esid));
        setLoading(false);
        notification.error({
          message: "Thông báo",
          description: "ESID đã hủy thành công."
        });
      } catch (error) {
        setLoading(false);
        notification.error({
          message: "Thông báo",
          description: "Có lỗi xảy ra. Vi lòng thử lại sau."
        });
      }
    }
  }

  const fillFormModal = (data) =>{
    regisForm.setFieldsValue({
      name: data?.namCtc,
      address: data?.desAddStt,
      email: data?.desEmail,
      tel: data?.desPhone,
      fax: data?.desFax,
      vat: data?.codTaxCmp
    });
  }

  const onChangeSelect = (value, type) => {
    const ctcCmp = cmpList.find((x) => x.id === value);
    fillFormData(type, ctcCmp);
    setItemData(ctcCmp);
    fillFormModal(ctcCmp);
  };
  const fillFormData = (title, data) => {
    if (data) {
      switch (title) {
        case CodCtcTypEnum.Shipper:
          form.setFieldsValue({
            shipperId: data.id,
            nameShp: data.namCtc,
            addressShp: data.desAddStt,
            emailShp: data.desEmail,
            telShp: data.desPhone,
            faxShp: data.desFax,
            vatShp: data.codTaxCmp,
          });
          break;
        case CodCtcTypEnum.Agent:
          checkExistsTaxCode(data?.codTaxCmp || '');
          form.setFieldsValue({
            agentId: data.id,
            nameAgt: data.namCtc,
            addressAgt: data.desAddStt,
            emailAgt: data.desEmail,
            telAgt: data.desPhone,
            faxAgt: data.desFax,
            vatAgt: data.codTaxCmp,
          });
          break;
        case CodCtcTypEnum.Consignee:
          form.setFieldsValue({
            consigneeId: data.id,
            nameCne: data.namCtc,
            addressCne: data.desAddStt,
            emailCne: data.desEmail,
            telCne: data.desPhone,
            faxCne: data.desFax,
            vatCne: data.codTaxCmp,
          });
          break;
        case CodCtcTypEnum.Notify:
          form.setFieldsValue({
            notifyId: data.id,
            nameNtf: data.namCtc,
            addressNtf: data.desAddStt,
            emailNtf: data.desEmail,
            telNtf: data.desPhone,
            faxNtf: data.desFax,
            vatNtf: data.codTaxCmp,
          });
          break;
        default:
          break;
      }
    }
  };
  const addItem = (value) => {
    setItemData(null);
    setVisibleModal(true);
    setTitleModal(value);
    setIsUpdate(false);
    regisForm.resetFields();
  };
  const updateItem = (value) => {
    const ctcCmp = cmpList.find((x) => x.id === value.id);
    setItemData(ctcCmp);
    fillFormModal(ctcCmp);
    setVisibleModal(true);
    setTitleModal(value.codCtcTyp);
    setIsUpdate(true);
  };
  const updateDataItem = (codType, data) => {
    fillFormData(codType, data);
  };

  const [timer, setTimer] = useState(null);
  const handleChangeCodApt = (evt, oriOrDes) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const str = evt.target.value;
      const apt = airports.find(
        (x) => x.codApt.toLocaleUpperCase() === str.toLocaleUpperCase()
      );
      const ctyApt = airports.find((x) => x.codCty === str.toLocaleUpperCase());
      if (apt || ctyApt) {
        // Get the next input field
        const nextSibling = document.querySelector(`input[name=flightNo]`);
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
      switch (oriOrDes) {
        case AptTypEnum.Origin:
          form.setFieldsValue({
            codOri: apt?.codApt || (str.length === 3 ? "" : str),
            originName: apt?.namApt || "",
          });
          setErrors({
            ...errors,
            errOri: apt ? null : t("formInfo.errOri"),
          });
          break;
        case AptTypEnum.Destination:
          form.setFieldsValue({
            codFds: apt?.codApt || ctyApt?.codCty || (str.length === 3 ? "" : str),
            destinationName: apt?.namApt || ctyApt?.namCty || "",
          });
          setErrors({
            ...errors,
            errFds: apt || ctyApt ? null : t("formInfo.errFds"),
          });
          break;
        default:
          break;
      }
    }, 500);
    setTimer(timeoutId);
  };

  const handleNextInput = (evt) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const { maxLength, value, name } = evt.target;
      // Check if they hit the max character length
      if (value.length >= maxLength && name === "codAwbPfx") {
        // Get the next input field
        const nextSibling = document.querySelector(`input[name=codAwbNum]`);
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }

      if (value.length >= maxLength && name === "codAwbNum") {
        // Get the next input field
        const nextSibling = document.querySelector(`input[name=codFds]`);
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }, 300);
    setTimer(timeoutId);
  };

  const [timerAwb, setTimerAwb] = useState(null);
  const handleCheckAwb = () => {
    clearTimeout(timerAwb);
    const timeoutId = setTimeout(async () => {
      const { codAwbPfx, codAwbNum } = form.getFieldsValue();
      if (codAwbPfx.length >= 3 && codAwbNum.length >= 8) {
        try {
          setLoading(true);
          const response = await EsidCheckAwb(codAwbPfx, codAwbNum);
          const { data } = response;
          const errMsg = data?.message || t("formInfo.hasAwb");
          setErrors({
            ...errors,
            hasEsid: data.hasAwb ?  errMsg : null,
          });
          setLoading(false);
        } catch (error) {
          setErrors({
            ...errors,
            hasEsid: null,
          });
          setLoading(false);
          errorHandling(error, history, location.pathname);
        }
      }
    }, 500);
    setTimerAwb(timeoutId);
  };

  const onChangeTempStorageCheckbox = (e) =>{
    const val = e.target.checked
    if(!val){
      form.setFieldsValue({
        shcColTemReq: '',
        tempTitle: ''
      })
    }
    setIsTempStorageCheckbox(val)
  }

  const [isPharma, setIsPharma] = useState(false);
  const onChangePharmaCheck = (evt) => {
    const val = evt.target.checked;
    if (val) {
      form.setFieldsValue({
        shcPer: true,
        shcColStr: true
      });
      setIsTempStorageCheckbox(val);
    }
    setIsPharma(val);
  }

  const removeAccentsInput = (evt) => {
    const val = evt.target.value;
    form.setFieldsValue({
      shpRegNam: removeAccents(val)
    }); 
  }

  // const btnVehicleRegistration = () => {
  //   window.scrollTo({ left: 0, top: 350 });
  //   dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.VhcRegis));
  // };

  const handleDeclDrg = () => {
    window.scrollTo({ left: 0, top: 350 });
    dispatch(setTabEsidVisibility(esidExpTabsEnum.Dgr));
  };

  // eslint-disable-next-line
  const handleCertLivAnm = () => {
    window.scrollTo({ left: 0, top: 350 });
    dispatch(setTabEsidVisibility(esidExpTabsEnum.LivAnm));
  };

  const handleDeclConsol = () => {
    window.scrollTo({ left: 0, top: 350 });
    dispatch(setTabEsidVisibility(esidExpTabsEnum.Consol));
  };

  const handleDrgGoodsOk = () => {
    setIsOpenDrgGoodsDeclModal(false);
  };

  const handleDrgGoodsCancel = () => {
    if (dgrSelectedValue && dgrSelectedValue?.id !== null) {
      let a = dgrSelectedValue.dgrItems;
      let b = dgrGoods.map(({ key, ...rest }) => ({ ...rest }));

      const isSameArray = JSON.stringify(a) === JSON.stringify(b);
      if (!isSameArray) {
        setIsOpenConfirmCloseDrgModal(true);
      } else {
        dispatch(setDisableEditDrgGood(true));
        dispatch(setIsVisableEditDrgGoodButton(true));
        dispatch(setDgrSelectedToEdit(null));

        setIsOpenDrgGoodsDeclModal(false);
        setIsOpenConfirmCloseDrgModal(false);
      }
    } else {
      setIsOpenDrgGoodsDeclModal(false);
      setIsOpenConfirmCloseDrgModal(false);
    }
  };

  const handleConfirmSave = () => {
    dispatch(setDisableEditDrgGood(true));
    dispatch(setIsVisableEditDrgGoodButton(true));
    dispatch(setDgrSelectedToEdit(null));

    setIsOpenDrgGoodsDeclModal(false);
    setIsOpenConfirmCloseDrgModal(false);
  };

  const handleConfirmDontSave = () => {
    dispatch(setDisableEditDrgGood(true))
    dispatch(setIsVisableEditDrgGoodButton(true))
    if(dgrSelectedValue && dgrSelectedValue?.id !== null){
      const previousDgrItems = dgrSelectedValue.dgrItems.map((items) => ({
        ...items,
        key: items.id
      }));
      dispatch(setDgrGoods(previousDgrItems));
      setIsOpenDrgGoodsDeclModal(false);
      setIsOpenConfirmCloseDrgModal(false);
    }else{
      dispatch(setDgrGoods([]));
      setIsOpenDrgGoodsDeclModal(false);
      setIsOpenConfirmCloseDrgModal(false);
    }
  };

  const handleLivAnmGoodsOk = () => {
    setIsOpenLivAnmGoodsDeclModal(false);
  };

  const handleLivAnmGoodsCancel = () => {
    // setIsOpenLivAnmGoodsDeclModal(false);
    if (livAnmsSelectedValue && livAnmsSelectedValue?.id !== null) {
      let a = livAnmsSelectedValue.certDtl;
      let b = livAnms.map(({ key, ...rest }) => ({ ...rest }));
      const isSameArray = JSON.stringify(a) === JSON.stringify(b);
      if (!isSameArray) {
        setIsOpenConfirmCloseLivAnmModal(true);
      } else {
        dispatch(setDisableEditLivAnmsFormDecl(true));
        dispatch(setIsVisableEditLivAnmButton(true));
        dispatch(setLivAnmSelectedToEdit(null));

        setIsOpenLivAnmGoodsDeclModal(false);
        setIsOpenConfirmCloseLivAnmModal(false);
      }
    } else {
      setIsOpenLivAnmGoodsDeclModal(false);
      setIsOpenConfirmCloseLivAnmModal(false);
    }
  };

  const handleConfirmSaveLivAnm = () => {
    dispatch(setDisableEditLivAnmsFormDecl(true));
    dispatch(setIsVisableEditLivAnmButton(true));
    dispatch(setLivAnmSelectedToEdit(null));

    setIsOpenLivAnmGoodsDeclModal(false);
    setIsOpenConfirmCloseLivAnmModal(false);
  };

  const handleConfirmDontSaveLivAnm = () => {
    dispatch(setDisableEditLivAnmsFormDecl(true))
    dispatch(setIsVisableEditLivAnmButton(true))
    if(livAnmsSelectedValue && livAnmsSelectedValue?.id !== null){
      const previousLivItems = livAnmsSelectedValue.certDtl.map((items) => ({
        ...items,
        key: items.id
      }));
      dispatch(setLivAnms(previousLivItems));
      setIsOpenLivAnmGoodsDeclModal(false);
      setIsOpenConfirmCloseLivAnmModal(false);
    }else{
      dispatch(setLivAnms([]));
      setIsOpenLivAnmGoodsDeclModal(false);
      setIsOpenConfirmCloseLivAnmModal(false);
    }
  };

  const handleCarDim = () => {
    window.scrollTo({ left: 0, top: 350 });
    dispatch(setTabEsidVisibility(esidExpTabsEnum.CarDim));
  }

  const [chkLoadSts, setChkLoadSts] = useState(false);
  const handleCheckout = async () => {
    if (!esidExport) {
      return;
    }
    const chkLoadRes = await ExpWebCheckLoadLst({
      COD_AWB_PFX: esidExport.codAwbPfx,
      COD_AWB_NUM: esidExport.codAwbNum,
      COD_AWB_SFX: esidExport.codAwbSfx,
      COD_HWB_NUM: esidExport.codHwb ?? '',
    });
    if (chkLoadRes?.data?.mCol?.length >= 1) {
        setChkLoadSts(false);
        dispatch(setExpBilChgLst(chkLoadRes.data.mCol));
        dispatch(setModalEsidVisibility({ [ModalName.ExpBillCharge]: true }));
    } 
    // else if (chkLoadRes?.data?.mCol?.length === 1) {
    //     setChkLoadSts(false);
    //     dispatch(setExpBilChg(chkLoadRes.data.mCol[0]));
    //     window.scrollTo(0, 380);
    //     history.push("/Esid/Checkout");
    // }
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }
      @media print {
        html, body {
          padding: 0.7rem 0.7rem 0.7rem 0.7rem;
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }
      @media print {
        .page-break {
          margin-top: 0.7rem;
          display: block;
          page-break-before: always;
        }
      }
      @media print {
        @page { 
          size: A4 portrait;
          margin: 0;
        }
      }
    `
  });

  const [taxCodeMsg, setTaxCodeMsg] = useState('');
  const [chkTimer, setChkTimer] = useState(null);
  const checkExistsTaxCode = (evt) => {
    clearTimeout(chkTimer);
    const timerId = setTimeout(async () => {
      try {
        const taxCode = typeof(evt) === 'string' ? evt : evt.target.value;
        if (!taxCode) {
          setTaxCodeMsg('');
          return;
        }
        const result = await CheckExistsTaxCode({ taxCode });
        if (result?.data?.hasCmp) {
          setTaxCodeMsg('');
        } else {
          setTaxCodeMsg('Mã số thuế chưa được đăng ký tại TCS. Vui lòng liên hệ tại quầy giao dịch để được hổ trợ.');
        }
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Thông báo',
          description: 'Có lỗi xảy ra. Vui lòng thử lại sau.'
        });
      }  
    }, 500);
    setChkTimer(timerId);
  }

  const renderHTML = (info) => {
    const dataOption = cmpList.filter((x) => x.codCtcTyp === info.title);
    const dataNotyfyOption =
      info.title === CodCtcTypEnum.Consignee
        ? cmpList.filter((x) => x.codCtcTyp === CodCtcTypEnum.Notify)
        : [];

    return (
      <div className="card-content" style={{ paddingBottom: 5 }}>
        <div className="card-title" style={{ height: 10 }}>
          <span> {t(`formInfo.${info.title}`)}</span>
        </div>
        {info.name && (
          <Row style={{width: '100%', margin: '10px 0'}}>
            {/* <Col span={4}>
              <label> {t("formInfo.name")} </label>
            </Col> */}
            <Col span={24}>
              <Form.Item
                labelAlign="left"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                label={t("formInfo.name")}
                style={{ margin: "0px" }}
                name={info.id}
                rules={[
                  {
                    required:
                      info.title === CodCtcTypEnum.Shipper ||
                      info.title === CodCtcTypEnum.Agent ||
                      info.title === CodCtcTypEnum.Consignee,
                    message: `${t(`formInfo.${info.name}_Err`)}`,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t("formInfo.search")}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.title.toLocaleLowerCase() ?? "").includes(
                      input.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLocaleLowerCase()
                      .localeCompare((optionB?.label ?? "").toLocaleLowerCase())
                  }
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={() => addItem(info.title)}
                        >
                          {t("formInfo.addCtcCmp")}
                        </Button>
                      </Space>
                    </>
                  )}
                  optionLabelProp="label"
                  name={info.id}
                  onChange={(value) => onChangeSelect(value, info.title)}
                >
                  {dataOption.map((x, idx) => (
                    <Option
                      value={x.id}
                      label={x.namCtc}
                      key={x.id}
                      title={`${[x.namCtc, x.desAddStt, x.desPhone]
                        .filter((i) => i)
                        .join(" - ")}`}
                      data={x}
                    // style={{ backgroundColor: idx % 2 !== 0 ? 'whitesmoke' : 'white' }}
                    >
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', position: 'relative' }}>
                        <div>
                          <div style={{ fontWeight: "bold" }}>
                            {[x.namCtc, x.desPhone].filter((i) => i).join(" - ")}
                          </div>
                          {x.desAddStt && (
                            <div style={{ fontStyle: "italic" }}>
                              {x.desAddStt}
                            </div>
                          )}
                        </div>
                        <div className="icon-updateItem">
                          <div>
                            <Button
                              type="text"
                              icon={<EditOutlined />}
                              onClick={() => updateItem(x)}
                            />
                          </div>
                        </div>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        {info.address && (
          <Row className="row-input">
            {/* <Col span={4}>
              <label> {t("formInfo.address")} </label>
            </Col> */}
            <Col span={24}>
              <Form.Item
                labelAlign="left"
                label={t("formInfo.address")}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                name={info.address}
                style={{ margin: "0px" }}
                rules={[
                  {
                    required:
                      info.title === CodCtcTypEnum.Shipper ||
                      info.title === CodCtcTypEnum.Agent ||
                      info.title === CodCtcTypEnum.Consignee,
                    message: `${t(`formInfo.${info.address}_Err`)}`,
                  },
                ]}
              >
                <Input type="text" name={info.address} maxLength={200}/>
              </Form.Item>
            </Col>
          </Row>
        )}
        {info.email && (
          <Row className="row-input">
            <Col span={7}>
              <label> Email </label>
            </Col>
            <Col span={17}>
              <Form.Item name={info.email} style={{ margin: "0px" }}>
                <Input type="text" name={info.email} maxLength={50}/>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[10, 0]} className="row-input">
          {info.tel && (
            <>
              <Col span={4}>
                <Row style={{ alignItems: "center" }}>
                  <label> {t("formInfo.tel")} </label>
                </Row>
              </Col>
              <Col span={3}/>

              <Col span={7}>
                <Form.Item name={info.tel} style={{ margin: "0px" }}>
                  <Input type="number" name={info.tel} maxLength={50} />
                </Form.Item>
              </Col>
            </>
          )}
          {info.fax && (
            <>
              <Col span={4}>
                <Row style={{ alignItems: "center" }}>
                  <label> {t("formInfo.fax")} </label>
                </Row>
              </Col>

              <Col span={6}>
                <Form.Item name={info.fax} style={{ margin: "0px" }}>
                  <Input type="text" name={info.fax} maxLength={50} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {info.vat && info.title !== CodCtcTypEnum.Shipper && (
          <Row className="row-input">
            <Col span={24}>
              <Form.Item
                labelAlign="left"
                label={t("formInfo.vatCode")}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                name={info.vat}
                style={{ margin: "0px" }}
                help={info.title === CodCtcTypEnum.Agent && taxCodeMsg} validateStatus={info.title === CodCtcTypEnum.Agent && taxCodeMsg ? "error" : ""}
                rules={[
                  {
                    required:
                      info.title === CodCtcTypEnum.Agent,
                    message: `${t(`formInfo.${info.vat}_Err`)}`,
                  },
                ]}
              >
                <Input type="text" name={info.vat} onChange={(evt) => {
                  if (info.title === CodCtcTypEnum.Agent) {
                    checkExistsTaxCode(evt);
                  }
                }} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {info.notify && (
          <Row className="row-input">
            <Col span={7}>
              <label style={{ fontWeight: 'bold' }}> {t("formInfo.NTF")} </label>
            </Col>
            <Col span={17}>
              <Form.Item style={{ margin: "0px" }} name={info.notifyId}>
                <Select
                  showSearch
                  placeholder={t("formInfo.search")}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.title.toLocaleLowerCase() ?? "").includes(
                      input.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLocaleLowerCase()
                      .localeCompare((optionB?.label ?? "").toLocaleLowerCase())
                  }
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={() => addItem(CodCtcTypEnum.Notify)}
                        >
                          {t("formInfo.addCtcCmp")}
                        </Button>
                      </Space>
                    </>
                  )}
                  optionLabelProp="label"
                  name={info.notifyId}
                  onChange={(value) =>
                    onChangeSelect(value, CodCtcTypEnum.Notify)
                  }
                >
                  {dataNotyfyOption.map((x, idx) => (
                    <Option
                      value={x.id}
                      label={x.namCtc}
                      key={x.id}
                      title={`${[x.namCtc, x.desAddStt, x.desPhone]
                        .filter((i) => i)
                        .join(" - ")}`}
                      data={x}
                    // style={{ backgroundColor: idx % 2 !== 0 ? 'whitesmoke' : 'white' }}
                    >
                      <div>
                        <div style={{ fontWeight: "bold" }}>
                          {[x.namCtc, x.desPhone].filter((i) => i).join(" - ")}
                        </div>
                        {x.desAddStt && (
                          <div style={{ fontStyle: "italic" }}>
                            {x.desAddStt}
                          </div>
                        )}
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        {info.address2 && (
          <Row className="row-input">
            <Col span={7}>
              <label> {t("formInfo.address")} </label>
            </Col>
            <Col span={17}>
              <Form.Item name={info.address2} style={{ margin: "0px" }}>
                <Input maxLength={200} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {info.tel2 && (
          <Row gutter={[10, 0]} className="row-input">
            <Col span={4}>
              <Row style={{ alignItems: "center" }}>
                <label> {t("formInfo.tel")} </label>
              </Row>
            </Col>
            <Col span={3} />
            <Col span={7}>
              <Form.Item name={info.tel2} style={{ margin: "0px" }}>
                <Input type="number" name={info.tel2} maxLength={50} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Row style={{ alignItems: "center" }}>
                <label> Fax </label>
              </Row>

            </Col>
            <Col span={6}>
              <Form.Item name={info.fax2} style={{ margin: "0px" }}>
                <Input type="text" name={info.fax2} maxLength={50}/>
              </Form.Item>
            </Col>
          </Row>
        )}
        {info.email2 && (
          <Row className="row-input">
            <Col span={7}>
              <label> Email </label>
            </Col>
            <Col span={17}>
              <Form.Item name={info.email2} style={{ margin: "0px" }}>
                <Input type="text" name={info.email2} maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {info.notify && (
          <Row className="row-input">
            <Col span={7}>
              <label> {t("formInfo.notify")} </label>
            </Col>
            <Col span={17}>
              <Form.Item name={info.desRmk001} style={{ margin: "0px" }}>
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
    );
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{width:'100%'}}>
        <Spin spinning={loadingData} tip={t("formInfo.loadingData")}>
          <Row justify="center">
            <Col span={24} md={24} lg={24} xl={24} className="awb-tab-content">
              <p className="registry-notice">{t("header.registryInvNotice")}</p>
              {visibleModal && (
                <RegisterModal
                  form={regisForm}
                  itemData={itemData}
                  visible={visibleModal}
                  title={titleModal}
                  setVisible={setVisibleModal}
                  updateData={updateDataItem}
                  isUpdate={isUpdate}
                />
              )}
              <ModalCutOffTime
                visible={visibleFlightTable}
                setVisible={setVisibleFlightTable}
                setFlightNo={setFlightNo}
                setFlightDate={setFlightDate}
              />
              <div>
                <Form
                  ref={mainRow}
                  layout="horizontal"
                  className="form-date"
                  form={form}
                  initialValues={{
                    createdDate: moment(today, dateFormat),
                    codPayMod: "CSH",
                  }}
                  autoComplete="off"
                  onFinish={handleFinish}
                  scrollToFirstError
                >
                  <Row gutter={[10, 40]}>
                    <Col span={24} lg={11}>
                      <Row
                        gutter={[10, 40]}
                        align="stretch"
                        style={{
                          height:
                            mainRow?.current?.clientHeight -
                              (footerRow?.current?.clientHeight || 0) || "100%",
                        }}
                      >
                        <Col span={24}>{renderHTML(infoShiper)}</Col>
                        <Col span={24}>{renderHTML(infoArgent)}</Col>
                        <Col span={24}>{renderHTML(infoConsignee)}</Col>
                      </Row>
                    </Col>
                    <Col span={24} lg={13}>
                      <div
                        className="card-content"
                        style={{
                          height:
                            mainRow?.current?.clientHeight -
                              114 -
                              (footerRow?.current?.clientHeight || 0) || "100%",
                        }}
                      >
                        <div className="card-title">
                          <span> {t("formInfo.airWaybillNumber")}</span>
                        </div>
                        {/* <Spin size="small" spinning={loading}> */}
                          <Row gutter={[10, 0]}>
                            <Col span={24} md={8}>
                              <Form.Item
                                name="codAwbPfx"
                                rules={[
                                  {
                                    required: true,
                                    len: 3,
                                    message: `${t("formInfo.awbnA_Err")}`,
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  name="codAwbPfx"
                                  maxLength={3}
                                  onKeyUp={handleNextInput}
                                  placeholder={t("Prefix")}
                                  onChange={(event) => {
                                    form.setFieldsValue({
                                      codAwbPfx: event.target.value.slice(0, 3)
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24} md={16}>
                              <Form.Item
                                name="codAwbNum"
                                rules={[
                                  {
                                    required: true,
                                    len: 8,
                                    message: `${t("formInfo.awbnB_Err")}`,
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  name="codAwbNum"
                                  maxLength={8}
                                  onKeyUp={handleNextInput}
                                  onChange={(event) => {
                                    form.setFieldsValue({
                                      codAwbNum: event.target.value.slice(0, 8)
                                    });
                                    handleCheckAwb();
                                  }}
                                  placeholder={t("AWB#")}
                                  suffix={loading ? <LoadingOutlined /> : ''}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              {errors.hasEsid && (
                                <div className="ant-form-item-explain ant-form-item-explain-error">
                                  <div role="alert">{errors.hasEsid}</div>
                                </div>
                              )}
                            </Col>
                          </Row>
                        {/* </Spin> */}
                        <Row gutter={[10, 0]}>
                          <Col span={24} md={24}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              label={t("formInfo.origin")} 
                              required
                            >
                              <Input.Group compact>
                                <Form.Item
                                  name="codOri"
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      message: `${t("formInfo.origin_Err")}`,
                                    },
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    name="codOri"
                                    style={{
                                      width: "30%",
                                      textTransform: "uppercase",
                                    }}
                                    maxLength={3}
                                    onChange={(evt) =>
                                      handleChangeCodApt(evt, AptTypEnum.Origin)
                                    }
                                  />
                                </Form.Item>
                                <Form.Item name="originName" noStyle>
                                  <Input
                                    type="text"
                                    name="originName"
                                    disabled
                                    style={{ width: "70%" }}
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            {errors.errOri && (
                              <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{errors.errOri}</div>
                              </div>
                            )}
                          </Col>
                          <Col span={24} md={24}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              label={t("formInfo.finalDestination")}
                              required
                            >
                              <Input.Group compact>
                                <Form.Item
                                  name="codFds"
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      message: `${t("formInfo.destination_Err")}`,
                                    },
                                  ]}
                                >
                                  <Input
                                    name="codFds"
                                    style={{
                                      width: "30%",
                                      textTransform: "uppercase",
                                    }}
                                    maxLength={3}
                                    onChange={(evt) =>
                                      handleChangeCodApt(
                                        evt,
                                        AptTypEnum.Destination
                                      )
                                    }
                                  />
                                </Form.Item>
                                <Form.Item name="destinationName" noStyle>
                                  <Input
                                    type="text"
                                    name="destinationName"
                                    disabled
                                    style={{ width: "70%" }}
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            {errors.errFds && (
                              <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{errors.errFds}</div>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row gutter={[10, 0]}>
                          <Col span={24} md={8}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              name="flightNo"
                              label={t("formInfo.flight")}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.flightNo_Err")}`,
                                },
                              ]}
                            >
                              <Input
                                style={{ background: "#d6d6d6" }}
                                readOnly
                                name="flightNo"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={8}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              name="datFltOri"
                              label={t("formInfo.date")}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.flightDate_Err")}`,
                                },
                              ]}
                            >
                              {/* <Input style={{ background: "#d6d6d6" }} readOnly /> */}
                              <DatePicker format={dateFormat} clearIcon={false} disabled={true} 
                                inputReadOnly={true} suffixIcon={null} style={{ width: '100%', background: "#d6d6d6" }}/>
                            </Form.Item>
                          </Col>
                          <Col span={24} md={8}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              name="flightBtn" 
                              label={" "}
                            >
                              <Button
                                onClick={() => setVisibleFlightTable(true)}
                                style={{ width: "fit-content", marginLeft: 0 }}
                              >
                                {t("selectFlight")}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[10, 0]}>
                          <Col span={24} md={12}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              name="qtyPcs"
                              label={t("formInfo.noOfPieces")}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.nop_Err")}`,
                                },
                              ]}
                            >
                              <InputNumber
                                name="qtyPcs"
                                min={0}
                                style={{ width: "100%" }}
                                type="number"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={12}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              name="totalOfHawbs"
                              label={t("formInfo.totalOfHAWBs")}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.tohawb_Err")}`,
                                },
                              ]}
                            >
                              <InputNumber
                                name="totalOfHawbs"
                                min={0}
                                style={{ width: "100%" }}
                                type="number"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <div
                              className="ant-col ant-col-24 ant-form-item-label"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <label
                                htmlFor="natureOfGoods"
                                className="ant-form-item-required"
                              >
                                {t("formInfo.natureOfGoods")}
                              </label>
                              <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                                <Form.Item
                                  name="shcConsol"
                                  noStyle
                                  valuePropName="checked"
                                >
                                  <Checkbox>{t("formInfo.consol")}</Checkbox>
                                </Form.Item>
                              </div>
                            </div>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              name="natureOfGoods"
                              extra={t("formInfo.nogHelp")}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.nog_Err")}`,
                                },
                              ]}
                            >
                              <Input type="text" name="natureOfGoods" style={{ textTransform: 'uppercase' }}/>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              label={t("formInfo.termOfPayment")}
                              name="codPayMod"
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.top_Err")}`,
                                },
                              ]}
                            >
                              <Select
                                name="codPayMod"
                                options={[
                                  {
                                    value: "CSH",
                                    label: `${t("formInfo.cash")}`,
                                  },
                                  {
                                    value: "CDT",
                                    label: `${t("formInfo.bankTransfer")}`,
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              label={<div style={{ fontWeight: 'bold' }}>{t("formInfo.serviceManual")}</div>}
                              style={{ margin: 0 }}
                            >
                              <Row gutter={[10, 5]}>
                                <Col span={24}>
                                  <Form.Item
                                    name="shcPer"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox disabled={isPharma}>
                                      {t("formInfo.perishable")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    name="shcOth002"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox onChange={onChangePharmaCheck}>
                                      {t("formInfo.Pharmaceutical")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    name="shcVal"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>{t("formInfo.valuable")}</Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    name="shcAvi"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>
                                      {t("formInfo.liveAnimal")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>

                                <Col span={15}>
                                  <Form.Item
                                    name="shcDgr"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>
                                      {t("formInfo.DangerousGoods")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={9}>
                                  <Form.Item
                                    name="shcBup"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>{t("formInfo.shcBup")}</Checkbox>
                                  </Form.Item>
                                </Col>
                                {/* <Col span={24}>
                                  <Form.Item
                                    name="shcVun"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>
                                      {t("formInfo.vulnerable")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col> 
                                */}
                                <Col span={24} style={{ fontWeight: 'bold' }}>
                                  {t("formInfo.vulnerable")}
                                </Col>
                                <Col span={1}>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    name="shcVunNml"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>{t("formInfo.regular")}</Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    name="shcEcort"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>{t("formInfo.escort")}</Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={9}>
                                  <Form.Item
                                    name="shcReinforce"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>{t("formInfo.reinforced")}</Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    name="shcDip"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>
                                      {t("formInfo.diplomatic")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    name="shcHum"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox>
                                      {t("formInfo.humanRemains")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    name="shcColStr"
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <Checkbox disabled={isPharma} onChange={onChangeTempStorageCheckbox}>
                                      {t("formInfo.tempStorage")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ alignItems: "center" }}>
                          <Col span={8}>
                            <label> {t("formInfo.tempRequest")} (<sup>o</sup>C):</label>
                          </Col>
                          <Col span={16}>
                            <Form.Item required={isCheckTempStorageCheckbox ? true : false}>
                              <Input.Group compact>
                                <Form.Item name="shcColTemReq" noStyle rules={isCheckTempStorageCheckbox ?
                                  [
                                    {
                                      required: isCheckTempStorageCheckbox ? true : false,
                                      message: t('dangerousGood.errorInfo.required'),
                                    },
                                  ] :
                                  [
                                    {
                                      required: false,
                                    },
                                  ]}
                                >
                                  <UNSelect form={form} disabled={!isCheckTempStorageCheckbox} isSelectOpen={isUNSelectOpen} setIsSelectOpen={setIsUNSelectOpen} />
                                </Form.Item>
                                {
                                  !isUNSelectOpen &&
                                  <Form.Item
                                    name="tempTitle"
                                    noStyle
                                  >
                                    <Input style={{ width: '70%' }} readOnly />
                                  </Form.Item>
                                }
                              </Input.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              name="shcOth"
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox>
                                {t("formInfo.other")}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Row gutter={[10, 10]}>
                              <Col span={24}>
                                <Form.Item
                                  name="shcOth001"
                                  noStyle
                                  valuePropName="checked"
                                >
                                  <Checkbox onChange={(evt) => setIsEditReqOth(evt.target.checked)}>
                                    {t("formInfo.otherRequest")}
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="shcOthReq"
                              style={{ marginBottom: 5 }}
                            >
                              <TextArea rows={2} disabled={!isEditReqOth}/>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="shcCod002"
                              style={{ marginBottom: 5 }}
                              valuePropName="checked"
                            >
                              <Checkbox style={{ fontWeight: 'bold', fontSize: 16 }}>
                                {t("Kho hàng TECS")}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{margin:'5px 0'}}>
                          <Col span={24} style={{display:'flex', width:'100%', alignItems:'center'}}>
                            <Typography.Text underline style={{ fontWeight: 'bold' }}>{t('formInfo.noteForCustomer')}</Typography.Text>
                          </Col>
                        </Row>
                        <Row style={{margin:'5px 0'}}>
                          <Col span={24} style={{display:'flex', width:'100%', alignItems:'center'}}>
                            <Typography.Text>
                              {t('formInfo.noteA')}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row style={{margin:'5px 0'}}>
                          <Col span={24} style={{display:'flex', width:'100%', alignItems:'center'}}>
                            <Typography.Text>
                              {t('formInfo.noteB')}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row style={{margin:'5px 0'}}>
                          <Col span={24} style={{display:'flex', width:'100%', alignItems:'center'}}>
                            <Typography.Text>
                              {t('formInfo.noteC')}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} style={{display:'flex', width:'100%', alignItems:'center'}}>
                            <Typography.Text>
                              {t('formInfo.noteD')}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div className="card-content" style={{ marginTop: 10, marginBottom: 10, paddingTop: 10 }}>
                    <Row justify="center" ref={footerRow}>
                      <Col span={23}>
                        <Form.Item
                          name="agreeConfirm"
                          valuePropName="checked"
                          rules={[
                            {
                              required: true,
                              message: `${t("formInfo.agreeConfirm")}`,
                            },
                          ]}
                        >
                          <Checkbox>{t("formInfo.agree")}</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={23}>
                        <p>{t("formInfo.comfirm")}</p>
                      </Col>
                    </Row>
                    <Row justify="center" ref={footerRow}>
                      <Col span={23}>
                        <Row gutter={[10, 10]}>
                          <Col sm={24} md={12} style={{width: '100%', alignItems: 'center'}}>
                            <Form.Item
                              labelAlign="left"
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              name="shpRegNam"
                              label={<div style={{ fontWeight: 'bold' }}>{t("formInfo.shipperFullName")}</div>}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.shipperFullName_Err")}`,
                                },
                              ]}
                            >
                              <Input type="text" name="shpRegNam" maxLength={50} style={{ textTransform: 'uppercase' }} onChange={removeAccentsInput}/>
                            </Form.Item>
                          </Col>
                          <Col sm={12} md={6} style={{ width: '100%', alignItems: 'center' }}>
                            <Form.Item
                              labelAlign="left"
                              name="shpRegTel"
                              label={t("formInfo.shipperTel")}
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.shipperTel_Err")}`,
                                },
                              ]}
                            >
                              <Input type="text" name="shpRegTel" />
                            </Form.Item>
                          </Col>
                          <Col sm={12} md={6} style={{ width: '100%', alignItems: 'center' }}>
                            <Form.Item
                              name="shpRegIdx"
                              label={t("formInfo.shipperID_Passport")}
                              labelAlign="left"
                              labelCol={{span: 24}}
                              wrapperCol={{span: 24}}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("formInfo.shipperID_Passport_Err")}`,
                                },
                              ]}
                            >
                              <Input type="text" name="shpRegIdx" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: 'wrap',
                        gap: '5px'
                      }}
                    >
                      {esidExport ? (
                        <>
                          <Button
                            onClick={createNewRegis}
                            style={{ width: "fit-content" }}
                          >
                            {t("formInfo.newRegis")}
                          </Button>
                          {['NY', 'VR'].includes(esidExport?.codSts) && (
                            <>
                              <Button
                                onClick={handleSaveEsidExp}
                                style={{ width: "fit-content" }}
                                loading={loading}
                              >
                                {t("admin.esid.saveBtn")}
                              </Button>
                              {/* <Button
                                onClick={handleCancelEsid}
                                style={{ width: "fit-content" }}
                                loading={loading}
                              >
                                {t("esidCancel")}
                              </Button> */}
                            </>
                          )}
                          <Button
                            onClick={handlePrint}
                            style={{ width: "fit-content" }}
                          >
                            {t("header.print")}
                          </Button>
                          {esidExport?.shcDgr === "Y" && (
                            <>
                              <Button
                                onClick={handleDeclDrg}
                                style={{ width: "fit-content" }}
                                disabled={esidExport?.shcDgr === "Y" ? false : true}
                              >
                                {t("formInfo.declDgrLabel")}
                              </Button>
                              <Modal
                                width={'80%'}
                                title={t("formInfo.declDgrLabel")}
                                visible={isOpenDrgGoodsDeclModal}
                                onOk={handleDrgGoodsOk}
                                onCancel={handleDrgGoodsCancel}
                                footer={null}
                              >
                                <TabExpDeclDgr />
                              </Modal>
                              <Modal
                                title={t("formInfo.confirmSave")}
                                visible={isOpenConfirmCloseDrgModal}
                                onOk={handleConfirmSave}
                                onCancel={handleConfirmDontSave}
                                cancelText={t("formInfo.cancelText")}
                              >
                                <Typography.Text strong>{t("formInfo.confirmMsg")}</Typography.Text>
                              </Modal>
                            </>
                          )}
                          {esidExport?.shcAvi === "Y" && (
                            <>
                              {/* <Button
                                onClick={handleCertLivAnm}
                                style={{ width: "fit-content" }}
                                disabled={esidExport?.shcAvi === "Y" ? false : true}
                              >
                                {t("formInfo.certLivAnmLabel")}
                              </Button> */}
                              <Modal
                                width={'80%'}
                                title={t("formInfo.certLivAnmLabel")}
                                visible={isOpenLivAnmGoodsDeclModal}
                                onOk={handleLivAnmGoodsOk}
                                onCancel={handleLivAnmGoodsCancel}
                                footer={null}
                              >
                                <TabExpCertLivAnm />
                              </Modal>
                              <Modal
                                title={t("formInfo.confirmSave")}
                                visible={isOpenConfirmCloseLivAnmModal}
                                onOk={handleConfirmSaveLivAnm}
                                onCancel={handleConfirmDontSaveLivAnm}
                                cancelText={t("formInfo.cancelText")}
                              >
                                <Typography.Text strong>{t("formInfo.confirmMsg")}</Typography.Text>
                              </Modal>
                            </>
                          )}
                          {esidExport?.shcConsol === 'Y' && (
                            <Button
                              onClick={handleDeclConsol}
                              style={{ width: "fit-content" }}
                              disabled={esidExport?.shcConsol === "Y" ? false : true}
                            >
                              {t("esid.expDeclConsol")}
                            </Button>
                          )}
                          {esidExport && esidExport?.codSts !== 'XX' && (
                            <Button
                              onClick={handleCarDim}
                              style={{ width: "fit-content" }}
                            >
                              {t("esid.expCarDim")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          loading={loading}
                          style={{ width: "fit-content" }}
                          htmlType="submit"
                          disabled={esidExport ? true : false}
                        >
                          {t("formInfo.submit")}
                        </Button>
                      )}
                      {/* <Button
                        onClick={btnVehicleRegistration}
                        style={{ width: "fit-content" }}
                      >
                        {t("formInfo.registration")}
                      </Button> */}
                      {esidExport && ['AC', 'AM', 'AP', 'QR', 'FN', 'OK'].includes(esidExport?.codSts) && (
                        <Button 
                          style={{ width: "fit-content" }} 
                          loading={chkLoadSts}
                          onClick={handleCheckout}
                        >
                          {t("esid.checkout")}
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </Form>
                {esidExport && (
                  <Row>
                    <Col span={24}>
                      <div style={{ flex: 1, alignItems: 'center', textAlign: 'center', color: esidExport.codSts === 'XX' ? 'red' : 'black' }}>
                        {t('esidExpList.table.status')}{': '}{t(`esid.codSts.${esidExport.codSts}`)}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              {numberQR && esidExport && (
                <Row>
                  <Col span={0}>
                    <RegistryExportPrint
                      ref={componentRef}
                      esidExport={esidExport}
                      dgrSelected={dgrSelectedValue}
                      aviSelected={livAnmsSelectedValue}
                      QR={QRToPrint}
                      numberQR={numberQR}
                    />
                  </Col>
                </Row>
              )}

              <Row className="result-wrapper">
                <Row className="result-wrapper">
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={24}
                    className="result"
                  >
                    <Row className="ticket-wrapper">
                      <Row className="control-button-group-wrapper">
                        <Col span={24} className="control qr-input-wrapper">
                          <Col className="qr-title">
                            {t("header.yourRegister")}
                          </Col>
                          {numberQR && <Col className="qr-no">{codeAwb}</Col>}
                        </Col>
                      </Row>
                      <Row className="control-button-group-wrapper">
                        <Col span={24} className="control qr-wrapper">
                          {QR}
                        </Col>
                        {QR && (
                          <Row className="awb-tab-title-wrapper">
                            <Col span={24} className="awb-tab-title">
                              <h5>{t("header.showQR")}</h5>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="registry-footer"
                              span={12}
                            >
                              {t("tabInfo.flightNo")}: {flightNo}
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="registry-footer"
                              span={12}
                            >
                              {/* {t("tabInfo.flightDate")}:{" "} */}
                              {moment(esidExport.datFltOri || flightDate).format(dateFormat)}
                            </Col>
                          </Row>
                        )}
                      </Row>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default TabRegistryExportInformation;
