import React, { useEffect, useState } from 'react'
import { Form, Typography, Input, Row, Col, Button, Select, InputNumber, Checkbox, DatePicker } from 'antd'
import { useTranslation } from "react-i18next";
import styles from '../../style.module.css';
import { useSelector } from 'react-redux';
import { AptTypEnum } from '../../../../../constant/enumerations';
import useWindowSize from '../../../../../helpers/useWindowSize';
import { ModalCutOffTime } from '../../../../../components/tracking/RegistryInvModal/ModalCutOffTime';
import moment from 'moment';
import { objectToAntFormData } from '../../../../../components/tracking/CertLivAnm/TabExpCertLivAnm';
import { formatFltDateInput } from '../../../../../utils/utils';
export const AwbInformationForm = ({form, readOnly}) => {
    const { t } = useTranslation("common");
    const selectAirports = useSelector(state => state.airport.airports);
    const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected);
    const [errors, setErrors] = useState({});
    const size = useWindowSize();
    const [timer, setTimer] = useState(null);
    const [fltNo, setFltNo] = useState('');
    const [fltDate, setFltDate] = useState('');
    const [isFlightTableVisible, setIsFlightTableVisible] = useState(false);
    
    const handleChangeCodApt = (evt, oriOrDes) => {
        clearTimeout(timer);
        const timeoutId = setTimeout(() => {
            const str = evt.target.value;
            if (str === '') {
                setErrors({
                    ...errors,
                    errOri: null,
                    errFds: null,
                });
            }
            else {
                const apt = selectAirports.find(
                    (x) => x.codApt.toLocaleUpperCase() === str.toLocaleUpperCase()
                );
                const ctyapt = selectAirports.find(
                    (x) => x.codCty.toLocaleUpperCase() === str.toLocaleUpperCase()
                );
                if (apt || ctyapt) {
                    // Get the next input field
                    const nextSibling = document.querySelector(`input[name=flightNo]`);
                    // If found, focus the next field
                    if (nextSibling !== null) {
                        nextSibling.focus();
                    }
                }
                switch (oriOrDes) {
                    case AptTypEnum.Origin:
                    form.setFieldsValue({
                        codOri: apt?.codApt || (str.length === 3 ? "" : str),
                        originName: apt?.namApt || "",
                    });
                    setErrors({
                        ...errors,
                        errOri: apt ? null : t("formInfo.errOri"),
                    });
                    break;
                    case AptTypEnum.Destination:
                    form.setFieldsValue({
                        codFds: apt?.codApt || ctyapt?.codCty || (str.length === 3 ? "" : str),
                        fdsName: apt?.namApt || ctyapt?.namCty,
                    });
                    setErrors({
                        ...errors,
                        errFds: apt || ctyapt ? null : t("formInfo.errFds"),
                    });
                    break;
                    default:
                    break;
                }
            }
        }, 500);
        setTimer(timeoutId);
    };
    const setFlightNo = (value) => {
        if (value) {
            setFltNo(value);
        }
    }
    const setFlightDate = (value) => {
        if (value) {
            setFltDate(value);
        }
    }

    useEffect(() => {
        if (fltDate && fltNo) {
            form.setFields(objectToAntFormData({
                datFltOri: moment(fltDate, 'DDMMMYYYY HH:mm'),
                flightNo: fltNo,
                // codFltCar: fltNo.substring(0,2),
                // codFltNum: fltNo.substring(2, fltNo.length),
            }))
        }
        // eslint-disable-next-line
    }, [fltDate, fltNo])

    return (
        <Form
            name={'airway-bill'}
            labelCol={{ span: 24 }}
            autoComplete="off"
            form={form}
            className={styles.personalForm}
        >
        <ModalCutOffTime
            visible={isFlightTableVisible}
            setVisible={setIsFlightTableVisible}
            setFlightNo={setFlightNo}
            setFlightDate={setFlightDate}
            dateIsString={true}
        />
        <Row gutter={10}>
            <Col lg={8} sm={24} xs={24}>
                <Form.Item label={<Typography.Text strong>{t("formInfo.airWaybillNumber")}</Typography.Text>}>
                    <Input.Group compact>
                        <Form.Item
                            name="codAwbPfx"
                            style={{marginBottom: '0 !important', width: '30%'}}
                            >
                            <Input placeholder={t('Prefix')} readOnly={readOnly} style={{ color: 'black', fontWeight: 'bolder' }}/>
                        </Form.Item>
                        <Form.Item
                            name="codAwbNum"
                            style={{marginBottom: '0 !important', width: '70%'}}
                            >
                            <Input readOnly={readOnly} placeholder={t('AWB#')} style={{ color: 'black', fontWeight: 'bolder' }} />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            </Col>
            <Col lg={8} sm={24} xs={24}>
                <Form.Item label={t("formInfo.origin")} style={{marginBottom: '0'}}>
                    <Input.Group compact>
                        <Form.Item 
                            className='country-input'
                            name="codOri" 
                            style={{ display: 'inline-block', width: '30%'}}
                            maxLength={3}
                            onChange={(evt) =>
                                handleChangeCodApt(
                                    evt,
                                    AptTypEnum.Origin
                                )
                            }
                        >
                            <Input readOnly={readOnly} />
                        </Form.Item>
                        <Form.Item 
                            className='airport-input'
                            name="originName" 
                            style={{ display: 'inline-block', width: '70%' }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                {errors.errOri && (
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{errors.errOri}</div>
                    </div>
                )}
            </Col>
            <Col lg={8} sm={24} xs={24}>
                <Form.Item label={t("formInfo.finalDestination")} >
                    <Input.Group compact>
                        <Form.Item 
                            className='country-input'
                            name="codFds" 
                            style={{ display: 'inline-block', width: '30%' }}
                            maxLength={3}
                            onChange={(evt) =>
                                handleChangeCodApt(
                                    evt,
                                    AptTypEnum.Destination
                                )
                            }
                        >
                            <Input readOnly={readOnly} />
                        </Form.Item>
                        <Form.Item 
                            className='airport-input'
                            name="fdsName" 
                            style={{ display: 'inline-block', width: '70%'}}
                        >
                            <Input disabled />
                        </Form.Item>
                </Input.Group>
                </Form.Item>
                {errors.errFds && (
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                    <div role="alert">{errors.errFds}</div>
                    </div>
                )}
            </Col>
            <Col lg={24} sm={24} xs={24}>
                <Row gutter={[10,10]}>
                    <Col span={24} md={ readOnly ? 12 : 8 }>
                        <Form.Item
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            name="flightNo"
                            label={t("formInfo.flight")}
                            style={{ fontWeight: 'bold' }}
                        >
                            <Input
                                size='large'
                                readOnly
                                name="flightNo"
                                style={{ fontWeight: 'bold', color: 'black' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={ readOnly ? 12 : 8 }>
                        <Form.Item
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            name="datFltOri"
                            label={t("formInfo.date")}
                            style={{ fontWeight: 'bold' }}
                        >
                            {/* <Input size='large' style={{ fontWeight: 'bold' }} readOnly /> */}
                            <DatePicker size='large' format={formatFltDateInput} clearIcon={false} disabled={true} 
                                inputReadOnly={true} suffixIcon={null} style={{ width: '100%', background: "#d6d6d6" }}/>
                        </Form.Item>
                    </Col>
                    {/* check for readOnly */}
                    {
                        !readOnly && (
                            <Col span={24} md={8}>
                                <Form.Item
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    name="flightBtn" 
                                    label={ size !== 'sm' && size !== 'xs' ? " " : null}
                                >
                                    <Button
                                        disabled={!selectEsidExport}
                                        onClick={() => setIsFlightTableVisible(true)}
                                        className={styles.submitBtn} 
                                        style={{ marginLeft: 0}}
                                    >
                                    {t("selectFlight")}
                                    </Button>
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>
            </Col>
            <Col xxl={12} xl={10} sm={24} xs={24}>
                <Row gutter={[10,10]}>
                    <Col span={24} md={10}>
                        <Form.Item
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            name="qtyPcs"
                            label={t("formInfo.noOfPieces")}
                            style={{ fontWeight: 'bold' }}
                        >
                            <InputNumber
                                name="qtyPcs"
                                min={0}
                                style={{ width: "100%", height: '100%', fontWeight: 'bolder' }}
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={14}>
                        <Form.Item
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            name="totalOfHawbs"
                            label={t("formInfo.totalOfHAWBs")}
                            style={{ fontWeight: 'bold' }}
                        >
                            <InputNumber
                                name="totalOfHawbs"
                                min={0}
                                style={{ width: "100%", height: '100%', fontWeight: 'bolder' }}
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col xxl={12} xl={14} sm={24} xs={24}>
                <Row gutter={[10,10]}>
                    <Col span={24} md={14}>
                        <Form.Item
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            name="natureOfGoods"
                            label={
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                                    <Typography.Text>{t("formInfo.natureOfGoods")}</Typography.Text>
                                    <Form.Item 
                                        name="shcConsol"
                                        noStyle
                                        valuePropName="checked"
                                    >
                                        <Checkbox>{t("formInfo.consol")}</Checkbox>
                                    </Form.Item>
                                </div>
                            }
                        >
                            <Input type="text" name="natureOfGoods" readOnly={readOnly} style={{ textTransform: 'uppercase' }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24} md={10}>
                        <Form.Item
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            label={t("formInfo.termOfPayment")}
                            name="codPayMod"
                        >
                            <Select
                            name="codPayMod"
                            disabled={readOnly}
                            options={[
                                {
                                value: "CSH",
                                label: `${t("formInfo.cash")}`,
                                },
                                {
                                value: "EBK",
                                label: `${t("formInfo.bankTransfer")}`,
                                },
                            ]}
                            />
                        </Form.Item>
                    </Col>      
                </Row>
            </Col>
        </Row>
     
        
    </Form>
    )
  }
